import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'

const LabelRepeat = (props) => {

    if(Number(props.repetition) > 4){
        return <span className="badge bg-just-in-time">{props.t("LABEL_2_FIRST_TIME")}</span>
    }

    if(Number(props.status) == 0 ){
        return <span className="badge bg-secondary">{props.t("LABEL_2_DRAFT")}</span>
    }

    if(Number(props.status) == 2 ){
        return <span className="badge bg-danger">{props.t("LABEL_2_REPEAT")}</span>
    }

    if(Number(props.status) == 1){
        return <span className="badge bg-just-in-time">{props.t("LABEL_2_FIRST_TIME")}</span>
    }

    if(Number(props.status) == 9 ){
        return <span className="badge bg-danger">{props.t("LABEL_2_TRASH")}</span>
    }

    return null
}

LabelRepeat.propTypes={
	repetition:PropTypes.number,
    status:PropTypes.number,
    isRepeated:PropTypes.bool,
    t:PropTypes.object
}

export default withTranslation()(LabelRepeat)

const InCycle = (props) => {

    let color = "#38b473"

    if( Number(props.status) == 2){
        color = "red"
    }

    if( [0,1].includes(Number( props.isRepeated )) && [2,3].includes( Number(props.status) ) ){
        return <span className="badge__incycle"><FontAwesomeIcon icon={faCircleNotch} color={color} /></span>
    }

    return null
}

InCycle.propTypes={
	repetition:PropTypes.number,
    status:PropTypes.number,
    isRepeated:PropTypes.number
}

export {
    InCycle
}