import React,{Component} from "react";

import PropTypes from 'prop-types';

import Notification from './../components/Notification'

import Api from './../helpers/api'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faInfo, faSave} from '@fortawesome/free-solid-svg-icons'

import Editor from "./../components/EditorQuill";

import Button from './../components/Button'

import DateTimePicker from "../components/DateTimePicker";

import moment from 'moment';

import { withTranslation } from 'react-i18next';

import Tooltip from "../components/Tooltip";

const defaultTime = "09:00:00"

class SaveForLater extends Component{
    constructor(props){
        super(props)
        this.state = {
            loading:false,
            description:"",
            cycle_learn_date:null,
            with_description:false,
            time:defaultTime
        }

        this.onChangeName = this.onChangeName.bind(this)
        this.onChangeDate = this.onChangeDate.bind(this)
        this.onChangeDescription = this.onChangeDescription.bind(this)
        this.onSave = this.onSave.bind(this)
    }

    componentDidMount(){
        this.setState({cycle_learn_date:this.props.date})
    }

    componentDidUpdate(){
        if(this.props.date != moment(this.state.cycle_learn_date).format('YYYY-MM-DD')){
            this.setState({cycle_learn_date:this.props.date})
        }
    }

    onChangeName(name){
        let that = this
        that.setState({name})
    }

    onChangeDate(cycle_learn_date){
        let time = moment(cycle_learn_date).format('HH:mm:ss')
        this.setState({time:time})
    }

    onChangeDescription(description){
        this.setState({description})
    }

    onSave(){

        if(!this.state.name){
            return null
        }

        this.setState({loading:true})

        try {
            Api.post('cycles/saveforlater',{
                name:this.state.name,
                description:this.state.description,
                cycle_learn_date:this.state.cycle_learn_date +" "+ this.state.time,
                is_for_learn:true,
                imageName:'',
            }).then((response)=>{

                this.setState({loading:false})

                if(response.status == "success"){

                    Notification.notify({
                        message: response.message ? response.message : [this.props.t("NOTIS_SAVED_FOR_LATER")],
                        type:"success"
                    });

                    this.props.onSuccess(response);
                    
                }else{
                    Notification.notify({
                        message:this.props.t("NOTIS_SOMETHING_WRONG"),
                        type:"danger"
                    });
                }

            }).catch(()=>{

                this.setState({loading:false})

                Notification.notify({
                    message:this.props.t("NOTIS_SOMETHING_WRONG"),
                    type:"danger"
                });
            })
        } catch (error) {

            this.setState({loading:false})

            Notification.notify({
                message:this.props.t("NOTIS_SOMETHING_WRONG"),
                type:"danger"
            });
        }
    }
    // <a href="#" data-toggle="tooltip" title="Hooray!">Hover over me</a>

    render(){
        return(

                <div className="row">
                    <div className="col-md-12 mb-3">
                        <input placeholder={this.props.t("SAVE_LATER_NAME")} name="name" onChange={(e)=>this.onChangeName(e.target.value)} value={this.state.name} className="form-control form-control-lg" />
                    </div>

                    <div className="col-md-12 mb-3">
                        <div className="row">
                            <div className="col-md-6">
                                <label className="mb-3">
                                <Tooltip
                                    key="Tooltip-1"
                                    component={
                                        <FontAwesomeIcon icon={faInfo} />
                                    }
                                    content={this.props.t("TOLTIP_SAVE_LATER")}
                                />
                                {" "}
                                {this.props.t("SAVE_LATER_TIME")}</label>
                            </div>
                            <div className="col-md-6">
                                <DateTimePicker  lng={ window.localStorage.getItem('i18nextLng') } onChange={this.onChangeDate} disabled={false} />
                            </div>
                        </div>
                    </div>

                    {
                        this.state.name ?
                        <div className="col-md-12 mb-3">
                            <Editor placeholder={this.props.t("SAVE_LATER_DESCRIPTION")} onChange={(d)=>{this.onChangeDescription(d);}} value={this.state.description} />
                        </div>
                        : null
                    }

                    <div className="col-md-12">
                        <Button disabled={this.state.name && this.state.name.length > 3 ?false:true} className="btn btn-sm btn-outline-success pull-right" load={this.state.loading} onClick={this.onSave}>
                            <FontAwesomeIcon icon={faSave} /> {this.props.t("SAVE_LATER_SAVE")}
                        </Button>
                    </div>
                </div>
        );
    }
}

SaveForLater.propTypes = {
    date:PropTypes.instanceOf(Date),
    onSuccess:PropTypes.func,
    show:PropTypes.bool,
    history:PropTypes.object,
    t:PropTypes.object
}

export default withTranslation()(SaveForLater)