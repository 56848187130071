import React from 'react';

import Layout from "./../components/Layout";

const name="Learn Cycle"
const author="Klevis Cipi"
const dateUpdate="2021-05-25"

const TermsAndConditionsEn = (props) => {

    return(
        <Layout withSidebar={false} {...props}>

            <div className="container">
                <h1>Terms and Conditions</h1>

                <p>
                    This is a legal agreement between users of this service ("you" or "your") and
                    {name}, a service created and started by the {author}. This agreement
                    governs your usage of {name} Web ("the service"). In this document,
                    "we" and "our" refers to {name} its staff, and trusted third-party
                        contractors.
                </p>
                
                <h2>Legal Age</h2>

                <p>If you are not old enough to legally agree to this document, please have a parent or guardian agree on your behalf.</p>
                
                <h2>Privacy</h2>

                <p>Please see our <a href="/account/privacy">privacy policy</a> to understand the
                information we store when you use the service, why we store it.</p>

                <h2>Account Expiry</h2>
                <p>Account will not be expired.</p>
                <p>In the future we will introduce an delete section which the user can delete the account by it's self.</p>

                <h2>Acceptable Content</h2>

                <p>By uploading or entering any material into our website, you assert that it
                    does not contain:</p>

                <ul>
                    <li>Content that you do not have the intellectual property rights to
                        use, due to copyright, trademark, patent or other protection. Your
                        local jurisdiction may allow you to use protected content under
                        fair use provisions, provided you do not share it with others.
                    </li>
                    <li>Content designed to subvert the correct functioning of software or
                    hardware, such as viruses and code exploits.</li>
                    <li>Content that is illegal EU, or your country of residence.</li>
                </ul>

                <p>If you are an intellectual property holder and believe your rights have been
                    violated, please see the "Intellectual Property Violations" section below.</p>

                {/* <p>If you share your material publicly, you must also ensure:</p>

                <ul>
                    <li>It does not contain content that might offend others, such as pornography, hate speech or libel.</li>
                    <li>It does not attempt to modify or replace the function, content or branding of our website.</li>
                </ul> */}
                <h2>Shared cycles</h2>
                <h4>Share</h4>

                <ul>
                    <li>You may optionally share (making it public) a cycle you have created, making it available for others to view and download from {name}.</li>
                    <li>When you share a cycle, you grant us a worldwide, royalty-free, non-exclusive license to make the deck available to users under the Shared Deck License below.</li>
                    <li>When you share a cycle it gives us the right to modify it in a limited way.</li>
                    <li>When you share a cycle it automatically entitles other authenticated users to place it in repetitive space to study it but without modifying it.</li>
                    <li>
                        You may elect to remove the shared cycle from our servers at any time. 
                        This will stop us from providing the cycle for download. 
                        We do not store records of who has downloaded a certain cycle, 
                        so we can not retroactively remove a cycle from users who have already downloaded it. 
                        Due to caching, there may be a small delay between when you remove a cycle and when it becomes unavailable to others.
                    </li>
                    <li>
                        In addition to the license you grant us above, you may optionally share a cycle, 
                        such as allowing them to redistribute the versions of your cycle without modifying it.
                    </li>
                    <li>
                        When you share an another cycle which is not created by you, 
                        you will be required to assert that it is entirely your own work, 
                        or that you have obtained a license from the intellectual property holder to share the material here. 
                        If we suspect that material has been uploaded in violation of intellectual property rights, 
                        we will remove it from the shared deck list, 
                        and may remove your ability to share decks in the future.
                    </li>
                    <li>
                        While we do not have the resources to manually check cycles that are uploaded to our website, 
                        we reserve the right to remove any we come across that we deem inappropriate, 
                        including but not limited to ones with poor quality descriptions or content, 
                        and ones that are only useful to a small group of people (such as lecture notes for a specific class).
                    </li>
                </ul>
                <h2>Shared Cycle License</h2>
                <ul>
                    <li>
                        When you view or download or either a cycle that someone has shared, 
                        the cycle author grants you a permanent, non-revocable, worldwide, royalty free, 
                        non-exclusive license to use the material in your personal studies.
                    </li>
                    <li>
                        This license is for personal use only, 
                        and the cycle may not be redistributed, re-uploaded, published, 
                        or used for any other purposes without explicit permission from the copyright holder.
                    </li>
                </ul>

                <h3>Intellectual Property Violations</h3>
                <p>
                    Each time users share a cycle, they're required to confirm that the content is entirely their own work, 
                    or that they have obtained a license from the intellectual property holder. 
                    If you believe someone has violated your intellectual property rights, 
                    please post a private message on our support site to let us know. 
                    Please provide us with a link to the offending content, and a link to your site, book or other original content that the material has been taken from. 
                    If you are writing on behalf of another, you must have the authority to act on their behalf. 
                    Please be advised that if you make a false report, you may place yourself open to legal action.
                </p>

                <h2>Access to the Service</h2>

                <ul>
                    <li>You may access {name} directly through your browser.</li>
                    <li>As the service is provided to you at no charge, we reserve the right to
    suspend or remove your access to the service at our sole discretion.</li>
                </ul>

                <h2>Violations &amp; Law Enforcement</h2>

                <p>If you fail to comply with these terms of service, we may suspend or remove
                your account at our sole discretion. If your actions were illegal, we may also
                report you to the authorities. We will provide your information to third
                parties when required to do so by law.</p>

                <h2>Money</h2>
                <p>
                    Use of the service is currently free, and the hosting costs are supported by the {author}. As the hosting
                    costs continue to grow, we may need to introduce a "freemium" model in the
                    future, where basic accounts are free, and people can pay for accounts that
                    support larger decks or extra features or we can introduce another one model.
                </p>

                <h2>Changes</h2>

                <p>If we make significant changes to these terms, we will notify you via a
                message on the website, or via an email.
                </p>

                <h2>Transfer</h2>

                <p>In the event of a service acquisition, restructure, or bankruptcy, we may transfer these rights
                    and obligations to the new legal entity.</p>

                <h2>Disclaimer of Warranties</h2>

                <p>Use of the service is at your own risk. While we endeavor to ensure the
                integrity of your data, ultimately the responsibility is in your hands. Please
                    keep a local backup of your data for safety's sake.</p>

                <p> THE SERVICE IS PROVIDED "AS IS". WHEN ALLOWED BY LOCAL LAW, WE HEREBY
                    DISCLAIM ALL WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING,
                    WITHOUT LIMITATION, THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
                    PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE MAKE NO WARRANTY THAT THE
                    SERVICE WILL BE ERROR FREE OR THAT ACCESS WILL BE CONTINUOUS OR
                    UNINTERRUPTED. YOU UNDERSTAND THAT USE OF THE SERVICE IS ENTIRELY AT YOUR
                    DISCRETION AND RISK.</p>

                <h2>Limitation of Liability</h2>

                <p> TO THE EXTENT NOT PROHIBITED BY LAW, IN NO EVENT SHALL WE BE LIABLE FOR
                    ANY GENERAL, SPECIAL, CONSEQUENTIAL, INCIDENTAL OR OTHER DAMAGES,
                    INCLUDING, WITHOUT LIMITATION, LOSS OF DATA, INCORRECT DATA, BUSINESS
                    INTERRUPTION, OR ANY OTHER DAMAGES OR LOSSES INCURRED BY YOUR USE OF, OR
                    INABILITY TO USE THIS SERVICE, EVEN IF WE HAVE BEEN ADVISED OF THE
                    POSSIBILITY OF SUCH DAMAGES, AND REGARDLESS OF THE THEORY OF LIABILITY.
                </p>

                <p><i>Last updated {dateUpdate}</i></p>
            </div>
        </Layout>
    );
    
}

const TermsAndConditionsAl = (props) =>{

    return(
        <Layout withSidebar={false} {...props}>

            <div className="container">
                <h1>Kushtet e përdorimit</h1>

                <p>
                    Kjo është një marrëveshje ligjore midis përdoruesve të këtij shërbimi ("ju" ose "juaj") dhe
                     {name}, një shërbim i krijuar dhe filluar nga {author}. Kjo marrëveshje
                     rregullon përdorimin tuaj të {name} Web ("shërbimi"). Në këtë dokument,
                     "ne" dhe "tonë" i referohet {name} stafit të saj dhe palës së tretë të besuar
                         kontraktorët.
                </p>
                
                <h2>Mosha Ligjore</h2>

                <p>Nëse nuk jeni aq të vjetër sa të pajtoheni ligjërisht me këtë dokument, ju lutemi konsultohuni me një prind ose kujdestar që të bien dakord.</p>
                
                <h2>Privatësia</h2>

                <p>Ju lutem shikoni <a href="/account/privacy">policën e sigurise</a> që të kuptoni
                informacionin që ne mbledhim kur ju përdorni këtë shërbim, dhe pse ne e mbledhim atë.</p>

                <h2>Skadenca e llogarise</h2>
                <p>Llogaria nuk do të skadojë.</p>
                <p>Në të ardhmen ne do të prezantojmë një seksion të fshirë të cilin përdoruesi mund ta fshijë llogarinë nga vetë ai</p>

                <h2>Përmbajtja e pranueshme</h2>

                <p>Duke ngarkuar ose futur ndonjë material në faqen tonë të internetit, ju pohoni se ai
                     nuk permban:</p>

                <ul>
                    <li>Përmbajtje për të cilën nuk keni të drejtat e pronësisë intelektuale
                        për përdorim, për shkak të të drejtës së kopjimit, markës tregtare, patentës ose mbrojtjes tjetër.Juridiksioni lokal I juaji
                        mund t'ju lejojë të përdorni përmbajtje të mbrojtur nën
                         dispozitat e përdorimit të drejtë, me kusht që të mos i ndani me të tjerët.
                    </li>
                    <li>Përmbajtja e krijuar për të përmbysur funksionimin e duhur të softuerit ose
                     harduer, siç janë viruset dhe shfrytëzimet e kodeve.</li>
                    <li>Përmbajtja që është e paligjshme e BE-së, ose vendi juaj i qëndrimit.</li>
                </ul>

                <p>Nëse jeni një mbajtës i pronës intelektuale dhe besoni se të drejtat tuaja kanë qenë
                     shkelur, shikoni seksionin "Shkeljet e Pronës Intelektuale" më poshtë.</p>

                {/* <p>If you share your material publicly, you must also ensure:</p>

                <ul>
                    <li>It does not contain content that might offend others, such as pornography, hate speech or libel.</li>
                    <li>It does not attempt to modify or replace the function, content or branding of our website.</li>
                </ul> */}
                <h2>Shpërndarja e cycles</h2>
                <h4>Shpërndarja</h4>

                <ul>
                    <li>Ju mund të ndani opsionalisht (duke e bërë atë publik) një cycle që keni krijuar, duke e bërë të disponueshëm për të tjerët për të parë dhe shkarkuar nga {name}.</li>
                    <li>Kur ndani një cycle, 
                        ju na jepni një licencë mbarëbotërore, pa të drejta mbretërore, 
                        jo-ekskluzive për ta bërë kuvertën të disponueshme për përdoruesit nën `Licensë e Shpërndarjes së Cycle` si më poshtë.</li>
                    <li>Kur ndani një cycle, kjo na jep të drejtën ta modifikojmë në një mënyrë të kufizuar..</li>
                    <li>Kur ndani një cycle, 
                        automatikisht përdoruesit e tjerë kanë të drejtë ta vendosin atë në hapësirë të përsëritur për ta studiuar, por pa e modifikuar atë duke u mbështetur nën `Licensë e Shpërndarjes së Cycle` si më poshtë. .
                    </li>
                    <li>
                    Ju mund të zgjidhni të hiqni cycle e përbashkët nga serverat tanë në çdo kohë.
                         Kjo do të na ndalojë të sigurojmë cycle për shkarkim.
                         Ne nuk ruajmë te dhëna se kush ka shkarkuar një cycle të caktuar,
                         kështu që ne nuk mund të heqim në mënyrë retroaktive një cycle nga përdoruesit që tashmë e kanë shkarkuar atë.
                         Për shkak të cache, mund të ketë një vonesë të vogël kur hiqni një cycle dhe kur ai bëhet i padisponueshëm për të tjerët.
                    </li>
                    <li>
                        Përveç licencës që na jepni më lart, mund të ndani një cycle opsionalisht,
                        u lejon përdoruesve të tjerë për të rishpërndarë versionin e cycle tuaj pa e modifikuar atë.
                    </li>
                    <li>
                        Kur ndani një cycle tjetër i cili nuk është krijuar nga ju,
                         nga ju do të kërkohet të pohoni se është plotësisht puna juaj,
                         ose që keni marrë një licencë nga mbajtësi i pronës intelektuale për të ndarë materialin këtu.
                         Nëse dyshojmë se materiali është ngarkuar në shkelje të të drejtave të pronësisë intelektuale,
                         ne do ta heqim atë nga lista e kuvertës së përbashkët,
                         dhe mund të heqë mundësine tuaj për të ndarë cycle në të ardhmen.
                    </li>
                    <li>
                        Meqenëse nuk kemi burime për të kontrolluar manualisht cycle që janë ngarkuar në faqen tonë të internetit,
                         ne rezervojmë të drejtën për të hequr çdo rast që na duket e papërshtatshme,
                         përfshirë por pa u kufizuar në ato me përshkrime ose përmbajtje me cilësi të dobët,
                         dhe ato që janë të dobishme vetëm për një grup të vogël njerëzish (të tilla si shënime leksionesh për një klasë specifike).
                    </li>
                </ul>
                <h2>Licensë e Shpërndarjes së Cycle</h2>
                <ul>
                    <li>
                        When you view or download or either a cycle that someone has shared, 
                        the cycle author grants you a permanent, non-revocable, worldwide, royalty free, 
                        non-exclusive license to use the material in your personal studies.
                    </li>
                    <li>
                        Kur shikoni ose shkarkoni ose perdorni një cycle që dikush opsionalisht ka ndar (bërë atë publike),
                        autori i ciklit ju jep një drejte të përhershëm, të pa revokueshëm, në të gjithë botën, pa të drejta mbretërore,
                        licencë jo-ekskluzive për të përdorur materialin për studimet tuaja personale.
                    </li>
                </ul>

                <h3>Shkeljet e Pronës Intelektuale</h3>
                <p>
                    Sa herë që përdoruesit ndajnë një cycle, atyre u kërkohet të konfirmojnë që përmbajtja është plotësisht punë e tyre,
                     ose që ata kanë marrë një licencë nga mbajtësi i pronës intelektuale.
                     Nëse besoni se dikush ka shkelur të drejtat tuaja të pronës intelektuale,
                     ju lutemi postoni një mesazh privat në faqen tonë të ndihmës për të na njoftuar.
                     Ju lutemi na siguroni një lidhje me përmbajtjen ofenduese dhe një lidhje me faqen tuaj, librin ose përmbajtjen tjetër origjinale nga e cila është marrë materiali.
                     Nëse jeni duke shkruar në emër të një tjetri, duhet të keni autoritetin të veproni në emër të tij.
                     Ju lutemi këshillohuni që nëse bëni një raport të rremë, mund ta vendosni veten të hapur ndaj veprimeve ligjore.
                </p>

                <h2>Aksesi tek Shërbimi</h2>

                <ul>
                    <li>Ju mund të përdorni {name} direkt përmes shfletuesit tuaj.</li>
                    <li>Meqenëse aktualisht shërbimi ju ofrohet pa pagesë, ne rezervojmë të drejtën për të
     pezulluar ose hequr te drejtën në hyrjen tuaj në shërbim sipas gjykimit tonë të vetëm.</li>
                </ul>

                <h2>Shkeljet & amp; Zbatimi i ligjit</h2>

                <p>Nëse nuk i përmbaheni këtyre kushteve të shërbimit, ne mund të pezullojmë ose heqim
                 llogarine tuaj sipas gjykimit tonë të vetëm. Nëse veprimet tuaja janë të paligjshme, ne gjithashtu mund të
                 raportojmë tek autoritetet. Ne do t'ia sigurojmë informacionin tuaj të palë së tretë kur kërkohet me ligj.</p>

                <h2>Paratë</h2>

                <p>
                    Përdorimi i shërbimit aktualisht është falas dhe kostot e pritjes mbështeten nga {author}. 
                    Sic pritet kostot vazhdojnë të rriten, mund të na duhet të prezantojmë një model "freemium" në
                     të ardhmen, ku llogaritë themelore janë falas, dhe njerëzit mund të paguajnë për llogari që
                     mbështesin kuverta më të mëdha ose karakteristika shtesë ose mund të prezantojmë një model tjetër.
                </p>

                <h2>Ndryshime</h2>

                <p>Nëse bëjmë ndryshime të rëndësishme në këto kushte, ne do t'ju njoftojmë përmes a
                 mesazh në faqen e internetit, ose përmes një emaili ose ato mund te konsultohet direkt në këtë faqe.
                </p>

                <h2>Transfer</h2>

                <p>Në rast të një blerje të këtij shërbimi, ristrukturimi ose falimentimi, ne mund t'i transferojmë këto të drejta
                     dhe detyrimet, personit të ri juridik.</p>

                <h2>Mohim i garancive</h2>

                <p>Përdorimi i shërbimit është në rrezikun tuaj. Ndërsa ne përpiqemi të sigurojmë
                 integriteti e të dhënave tuaja, në fund të fundit përgjegjësia është në duart tuaja. Ju lutem
                     mbani një kopje rezervë lokale të të dhënave tuaja për hir të sigurisë.</p>

                <p> SHËRBIMI OFROHET ASHTU SIÇ ËSHTË. 
                    KUR LEJOHET NGA LIGJI VENDOR, NE HEQIM DORË NGA TË GJITHA GARANCITË E ÇDO LLOJI, 
                    TË SHPREHURA OSE TË NËNKUPTUARA, DUKE PËRFSHIRË, PA KUFIZIM, GARANCITË E TREGTISË, 
                    AFTËSINË PËR QËLLIME TË VEÇANTA DHE MOS-SHKELJEN. 
                    NE NUK JAPIM ASNJË GARANCI SE SHËRBIMI DO TË JETË PA GABIME OSE SE AKSESI DO TË JETË I VAZHDUESHËM OSE I PANDËRPRERË. 
                    JU E KUPTONI QË PËRDORIMI I SHËRBIMIT ËSHTË PLOTËSISHT NË DISKRECIONIN DHE RREZIKUN TUAJ.</p>

                <h2>Kufizimi i përgjegjësisë</h2>

                <p> 
                    NË MASËN QË NUK ËSHTË E NDALUAR NGA LIGJI, 
                    NË ASNJË RAST NUK DO TË JEMI PËRGJEGJËS PËR NDONJË DËM TË PËRGJITHSHËM, TË VEÇANTË, PASUES, 
                    RASTËSOR OSE DËMTIM TJETËR, DUKE PËRFSHIRË, PA KUFIZIM, HUMBJEN E TË DHËNAVE, TË DHËNA TË PASAKTA, 
                    NDËRPRERJE TË BIZNESIT, OSE NDONJË DËMTIM OSE HUMBJE TJETËR TË SHKAKTUAR NGA PËRDORIMI JUAJ, 
                    OSE PAAFTËSIA PËR TË PËRDORUR KËTË SHËRBIM, EDHE NËSE NE JEMI KËSHILLUAR PËR MUNDËSINË E DËMTIMEVE TË TILLA, 
                    DHE PAVARËSISHT NGA TEORIA E PËRGJEGJËSISË.
                </p>

                <p><i>Modifikimi i fundit {dateUpdate}</i></p>
            </div>
        </Layout>
    );
}

const TermsAndConditions = (props) => {
    const lng = window.localStorage.getItem('i18nextLng') || "en"

    if(lng=="sq"){
        return <TermsAndConditionsAl {...props} />
    }
    return <TermsAndConditionsEn {...props} />
}

export default TermsAndConditions;