import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Header from './Header';
import Footer from "./../components/Footer";
import Sidebar from './Sidebar';

import Api from '../helpers/api.js'

import { isLogin } from "../utils/index";

import {setHtmlLang} from '../utils/Client';
class Layout extends Component {

    constructor(props) {
        super(props);
        this.state = {
            auth:null
        }
        this.getAuth = this.getAuth.bind(this)
    }
    getAuth(){
        Api.get('auth').then((response)=>{
            if(response.status=="success"){
                let auth = response.result
                this.setState({
                    auth:auth
                })
                setHtmlLang(auth.language)
            }
        })
    }
    componentDidMount(){
        if(isLogin()){
            this.getAuth()
        }

        this.setDescription()
    }
    setDescription(){
        if(!window.location.pathname.match(/cycle\/view/g)){
            window.document.title  =  "Learn Cycle"

            if(typeof window.sessionStorage.getItem('cycle_route_name') !="undefined" ){
                window.document.title = window.document.title +" | " +window.sessionStorage.getItem('cycle_route_name')
            }

            document.querySelector('meta[name="description"]').setAttribute("content", "Learn Cycle helps you to achieve the excellence.");
        }
    }
    getContent(){

        const childrenWithProps = React.Children.map(this.props.children, child => {
            // checking isValidElement is the safe way and avoids a typescript error too
            if (React.isValidElement(child)) {
              return React.cloneElement(child, { auth: this.state.auth||null});
            }
            return child;
          });
      

        if( !this.props.withSidebar){
            return (
                <>
                    <div className="row">
                        <main className="col-md-12"> 
                            {childrenWithProps}
                        </main>
                    </div>
                </>
            )
        }

        return(
            <>
                <div className="row">
                    <Sidebar {...this.props} auth={this.state.auth} />
                    <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4 layout layout-right">
                        <div className="justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3">
                            {childrenWithProps}
                        </div>
                    </main>
                </div>
            </>
        )
    }

    render() {
        return (
            <>
                <Header {...this.props} auth={this.state.auth} />
                    <br />
                    <br />
                    <br />
                    <div className={this.props.container}> {this.getContent()} </div>
                <Footer />
            </>
        );
    }
}

Layout.defaultProps = {
    container:"container",
    withSidebar:true
}

Layout.propTypes={
	withSidebar:PropTypes.bool,
    children:PropTypes.element,
    container:PropTypes.string,
    match:PropTypes.object
}

export default Layout;
