import React from 'react'
import Avatar from "./Avatar";
import PropTypes from 'prop-types';
import Api from '../helpers/api';


export default class UplodAvatar extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            tmp:null
        }
        this.onOpenUpload = this.onOpenUpload.bind(this)
        this.onChange = this.onChange.bind(this)
    }

    onChange(e){
        let form = new FormData();
        form.append(this.props.name,e.target.files[0])
        Api.putFile(this.props.name,form).then((response)=>{
            console.log("eeeee",response)
            if(response.result){
                //93f2af5bc4bd7cdf7706f6e8c6bbb60d.png
                this.setState({
                    tmp:response.result
                })
            }
        })
    }

    onOpenUpload(){
        var fileupload = document.getElementById(this.props.name);
        fileupload.click()
    }

    getPath(){
        
        let value = this.state.tmp||this.props.path||null

        if(!value){
            return "/assets/img/avatar.png" 
        }
        if(Api.getDomain()=="https://local-space.local"){
            return "/assets/img/avatar_fake.jpeg";
        }

        return Api.getDomain()+Api.DS+"avatar"+Api.DS+value
    }

    render(){
        return(
            <div>
                 <input id={this.props.name} name={this.props.name} type="file" style={{display:"none"}} onChange={this.onChange}/>
                <Avatar path={this.getPath()} width="150px" height="150px" upload={true} onClick={this.onOpenUpload} />
            </div>
        )
    }
}

UplodAvatar.defaultProps = {
    name:"avatar"
}

UplodAvatar.propTypes = {
    name:PropTypes.string,
    path:PropTypes.string
}