import React from 'react';
import Layout from "../components/Layout"
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

const ResetThankYou = (props) => {
    
    const email = props.match.params.email
    
    return(
        <>
            <Layout withSidebar={false} {...props} >
            <br/><br/>
            <h3 className="mr-3">
                {props.t("THANK_YOU_SEND")} {email}
                <small className="text-muted"> {props.t("THANK_YOU_CHECK")}</small>
            </h3>
            </Layout>
        </>
    );
}

ResetThankYou.propTypes = {
    match: PropTypes.object,
    history:PropTypes.object,
    t:PropTypes.object
}

export default withTranslation()(ResetThankYou);