import React  from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

const Modal = (props) => {

    const id =  props.id ?  "#" + props.id :  "#idModal" 

    const button = () => {
        let style={
            display:props.nobutton?'none':"block"
        }
        return <button id={ props.id+"-modal"} type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target={id} style={style} >
            Open
        </button>
    }
   
    const header = () => {
        if(props.noTitle){
            return null
        }
        return(
            <div className="modal-header">
                <h5 className="modal-title" id="staticBackdropLabel">{props.title||"Title Here"}</h5>
                <span id={props.id+"-modal-dismis"} style={{cursor:"pointer",color:"red"}} data-bs-dismiss="modal" aria-label="Close"> <FontAwesomeIcon icon={faTimes} /></span>
            </div>
        )
    }

    return(
        <>
        {props.button || button()}
        <div className={props.className||"modal fade"} id={props.id||"idModal"} data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className={"modal-dialog " + props.sizeClass}>
                <div className="modal-content">
                    {header()}
                    {props.children}
                </div>
            </div>
        </div>
        </>
    )

};

Modal.defaultProps ={
    sizeClass:'modal-lg'
}

Modal.propTypes={
	id:PropTypes.string,
    noTitle:PropTypes.bool,
    title:PropTypes.string,
    button:PropTypes.element,
    className:PropTypes.string,
    bg:PropTypes.string,
    children:PropTypes.arrayOf(PropTypes.element),
    nobutton:PropTypes.bool,
    sizeClass:PropTypes.string
}

export default Modal;


class ModalFooter extends React.Component {
    constructor(props){
		super(props);
	}
	render() {
        const style = this.props.style ? this.props.style : {};
        const bg = this.props.bg||""
		return (
			<div className={"modal-footer " + bg} style={style}>
				{this.props.children}
			</div>
		)
	}
}

ModalFooter.propTypes={
	style:PropTypes.object,
    className:PropTypes.string,
    bg:PropTypes.string,
    children:PropTypes.element,
}


class ModalBody extends React.Component {
	constructor(props){
		super(props);
	}
	getModalClass(){
		return "modal-body " + (this.props.className ? this.props.className : "");
	}
	render() {
		return (
			<div className={this.getModalClass()}>
				{this.props.children}
			</div>
		)
	}
}

ModalBody.propTypes={
    className:PropTypes.string,
    children:PropTypes.element,
}

export {
	ModalFooter,
	ModalBody
}

  