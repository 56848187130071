import React from 'react';
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import { store } from 'react-notifications-component';

const baseObjectNoti = {
    title: "Message",
    message:"Please check this out!",
    type: "success",
    insert: "bottom",
    container: "bottom-left",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 5000,
      onScreen: true
    }
  }


const Notifications = () => {

    return (<ReactNotification />);
};


Notifications.notify = (obj) =>{

    if(Array.isArray(obj.message)){
        obj.message.map((m)=>{
            store.addNotification({...baseObjectNoti,...obj,...{message:m}});
        })
    }else{
        store.addNotification({...baseObjectNoti,...obj});
    }
}

export default Notifications;