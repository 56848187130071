import React, {useState,useCallback} from 'react'

import {Helmet} from 'react-helmet'

import PropTypes from 'prop-types';

import debounce from "lodash/debounce";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faEdit,faTrash,faFile,faCircleNotch, faShare, faGlobe } from '@fortawesome/free-solid-svg-icons'

import { TagView } from "./../components/Tag";

import Api from '../helpers/api'

import { getTagFrom } from "../helpers/index";

import Button from './../components/Button'

import Notification from "../components/Notification";


import Confirm from "../components/Confirm";

import Tooltip from "../components/Tooltip";

import Favorite from "../components/Favorite";

import LabelRepetition from '../components/LabelRepetition'

import LabelRepeat,{ InCycle } from '../components/LabelRepeat'

import { validURL } from "../utils/Client";

import { withTranslation } from 'react-i18next';

import { isLogin,getImageFromBase64,getTextFromHtml } from '../utils/index';

import {CopyToClipboard} from 'react-copy-to-clipboard';

import Author from '../components/Author';

const CycleView =  (props) => {

    const[favorite,setFavorite] =  useState(props.isFavorite == "false" || props.isFavorite == false ? false : true)

    const[loadingrepeat,setLoadingRepeat] = useState(false)

    const[loadingmakecycle,setLoadingMakeCycle] = useState(false)

    const[loadingdelete,setLoadingDelete] = useState(false)

    const[laodingback,setLoadingBack] = useState(false)

    const[shared,setShared] = useState(false)

    const short_link = props.cycle.short_link
    const cycle_id = props.cycle.id
    const share_id = props.id
    const user_id = props.user_id
    const user_original_id = props.user_original_id
    const status = props.status
    const name = props.cycle.name
    const description = props.cycle.description
    const tags = props.cycle.tags
    const isFavorite = props.isFavorite
    const repetition = props.repetition
    const is_public = props.cycle.is_public=="true"||props.cycle.is_public==true ? true : false
    const auth_user_id = props.auth && props.auth.id ? props.auth.id : null
    const reloadData = props.onReload

    const repeateHandler = useCallback(
        debounce(() => {

            try {
                Api.post('cycles/repeat/'+cycle_id+"/"+share_id,{name,description,tags:getTagFrom(tags)||[]}).then((response)=>{

                    setLoadingRepeat(false)

                    if(response.status == "success"){

                        Notification.notify({
                            message: response.message ? response.message : [props.t("NOTIS_REPEATED"),props.t("NOTIS_LEAVE")],
                            type:"success"
                        });
                        
                        props.onReload()

                    }else{
                        Notification.notify({
                            message:props.t("NOTIS_SOMETHING_WRONG"),
                            type:"danger"
                        });
                    }

                }).catch(()=>{
                    setLoadingRepeat(false)
                    Notification.notify({
                        message:props.t("NOTIS_SOMETHING_WRONG"),
                        type:"danger"
                    });
                })
            } catch (error) {
                setLoadingRepeat(false)
                Notification.notify({
                    message:props.t("NOTIS_SOMETHING_WRONG"),
                    type:"danger"
                });
            }

        }, 1000),
        [setLoadingRepeat,cycle_id,share_id,name,description,tags]
    );

    const repeateMakeCycleHandler = useCallback(
        debounce(() => {

            try {

                let values = {
                    name,
                    description,
                    tags:getTagFrom(tags)||[],
                    status:3,
                    repetition:1,
                    isRepeated:true
                }

                if( Number(repetition) > 4 ){
                    values.isRecycle = true
                }

                Api.post('cycles/update/'+cycle_id+"/"+share_id,values).then((response)=>{

                    setLoadingMakeCycle(false)

                    if(response.status == "success"){

                        console.log("repeateMakeCycleHandler")

                        Notification.notify({
                            message: response.message ? response.message : [props.t("NOTES_SAVED_KEEP_LEARNING")],
                            type:"success"
                        });
                        
                        reloadData()

                    }else{
                        Notification.notify({
                            message:props.t("NOTIS_SOMETHING_WRONG"),
                            type:"danger"
                        });
                    }

                }).catch(()=>{
                    setLoadingMakeCycle(false)
                    Notification.notify({
                        message:props.t("NOTIS_SOMETHING_WRONG"),
                        type:"danger"
                    });
                })
            } catch (error) {
                setLoadingMakeCycle(false)
                Notification.notify({
                    message:props.t("NOTIS_SOMETHING_WRONG"),
                    type:"danger"
                });
            }

        }, 1000),
        [setLoadingMakeCycle,cycle_id,share_id,name,description,tags,repetition,reloadData]
    );

    const deleteHandler = useCallback(
        debounce(() => {

            try {
                let url = 'cycles/trashit/'+cycle_id+"/"+share_id

                if( Number(status) == 9 ){
                    url = 'cycles/delete/'+cycle_id+"/"+share_id
                }

                Api.post(url).then((response)=>{

                    if(response.status=="success"){
                        Notification.notify({
                            message:props.t("NOTIS_REQUEST_ACCEPTED"),
                            type:"success"
                        });
                        
                        if(Number(status) == 9){
                            window.location.href = window.location.origin +"/cycles/prepared"
                        }else{
                            window.location.href = window.location.origin +"/cycles/trash"
                        }
                        
                    }else{
                        Notification.notify({
                            message:props.t("NOTIS_SOMETHING_WRONG"),
                            type:"danger"
                        });
                    }

                    setLoadingDelete(false)

                }).catch(()=>{

                    setLoadingDelete(false)

                    Notification.notify({
                        message:props.t("NOTIS_SOMETHING_WRONG"),
                        type:"danger"
                    });
                })
            } catch (error) {

                setLoadingDelete(false)

                Notification.notify({
                    message:props.t("NOTIS_SOMETHING_WRONG"),
                    type:"success"
                });
            }

        }, 1000),
        [setLoadingDelete,cycle_id,share_id,status]
    );

    const debounceFavoriteHandler = useCallback(

        debounce((value) => {
            let url  = 'cycles/favorite/'+cycle_id+"/"+share_id

            try {

                Api.post(url,{
                    isFavorite:value
                }).then(()=>{

                }).catch(()=>{

                    Notification.notify({
                        message:props.t("NOTIS_SOMETHING_WRONG"),
                        type:"danger"
                    });
                })
            } catch (error) {

                Notification.notify({
                    message:props.t("NOTIS_SOMETHING_WRONG"),
                    type:"danger"
                });
            }

        }, 1000),
        [cycle_id,share_id]
    );

    const backToDraftHandler = useCallback(
        debounce(() => {

            try {
                Api.post('cycles/draftit/'+cycle_id+"/"+share_id,{
                    status:0,
                    repetition:0,
                }).then((response)=>{

                    setLoadingRepeat(false)

                    if(response.status == "success"){

                        Notification.notify({
                            message: ["Cycle back to draft successfully!"],
                            type:"success"
                        });
                        
                        setTimeout(()=>{
                            window.location.href = "/cycle/view/"+short_link
                        },2000)

                    }else{
                        Notification.notify({
                            message:props.t("NOTIS_SOMETHING_WRONG"),
                            type:"danger"
                        });
                    }

                }).catch(()=>{
                    setLoadingRepeat(false)
                    Notification.notify({
                        message:props.t("NOTIS_SOMETHING_WRONG"),
                        type:"danger"
                    });
                })
            } catch (error) {
                setLoadingRepeat(false)
                Notification.notify({
                    message:props.t("NOTIS_SOMETHING_WRONG"),
                    type:"danger"
                });
            }

        }, 1000),
        [setLoadingRepeat,cycle_id,share_id,short_link,name,description,tags,isFavorite]
    );

    const onCircle = useCallback(
        (value) => {
            setLoadingRepeat(true)
            repeateHandler(value);
        },
        [repeateHandler,setLoadingRepeat]
    );

    const onMakeCycle = useCallback(
        (value) => {
            setLoadingMakeCycle(true)
            repeateMakeCycleHandler(value);
        },
        [repeateMakeCycleHandler,setLoadingMakeCycle]
    );

    const onDelete = useCallback(
        (value) => {
            setLoadingDelete(true)
            deleteHandler(value);
        },
        [deleteHandler,setLoadingDelete]
    );

    const onConfimBack = useCallback(
        (value) => {
            setLoadingBack(true)
            backToDraftHandler(value);
        },
        [backToDraftHandler,setLoadingBack]
    );

    const onFavorite = useCallback(
        () => {
            let f = favorite ? false : true
            setFavorite(f)
            debounceFavoriteHandler(f);
        },
        [debounceFavoriteHandler,favorite]
    );

    const onConfimDelete = () =>{
        Confirm({
            title:props.t("CONFIRM_MESSAGE_ATTENT"),
            message: props.t("CONFIRM_MESSAGE_TRASH"),
            buttons: [
              {
                label: props.t("CONFIRM_BUTTON_TRASH"),
                onClick: () => onDelete(),
                className:"btn-danger"
              },
              {
                label:props.t("CONFIRM_BUTTON_NO"),
                onClick: () =>{}
              }
            ]
          })
    }

    const isAuthor = () =>{
        if(Number(auth_user_id) == Number(user_original_id)){
            return true
        }
        return false
    }

    const isSharedByMe = () => {
        if(Number(auth_user_id) == Number(user_id) ){
            return true
        }
        return false
    }

    const showButtons = () =>{

        if(!isLogin()){
            return null;
        }
        
        let buttons = []

        if(
            (props.isRepeated == "false" || props.isRepeated == false) 
            && [2].includes( Number(props.status) )
            && Number(props.repetition) < 5 
            && ( isAuthor() || isSharedByMe() )
        ){
            buttons.push(
                <Tooltip
                    key="Tooltip-1"
                    component={
                        <Button onClick={ loadingrepeat ==false ? ()=>onCircle() : null } className="btn text-warning btn-sm" load={loadingrepeat} >
                            <FontAwesomeIcon icon={faCircleNotch} /> {props.t("NEW_CYCLE_REPEAT")}
                        </Button>
                    }
                    content={props.t("TOLTIP_REPEAT")}
                />
            )
        }

        if( ( ( [0,1].includes(Number(props.status)) || Number(props.repetition) > 4) && ( isAuthor() || isSharedByMe() ) ) || ( !isAuthor() && !isSharedByMe() )){
            buttons.push(
                <Tooltip
                key="Tooltip-2"
                component={
                    <Button onClick={ loadingmakecycle ==false ? ()=>onMakeCycle() : null} className="btn text-success  btn-sm" load={loadingmakecycle} >
                        <FontAwesomeIcon icon={faCircleNotch} />
                         {" "} {  Number(props.repetition) < 5 ? props.t("NEW_CYCLE_MAKE_CYCLE") : props.t("NEW_CYCLE_MAKE_CYCLE_AGAIN") }
                    </Button>
                }
                content={props.t("TOLTIP_MAKE_CYCLE")}
            />)
        }
        
        if(isAuthor()){
            buttons.push(
                <button key="buttons-2" onClick={()=>props.setIsedit(true)} type="button" className="btn text-dark  btn-sm">
                    <FontAwesomeIcon icon={faEdit} /> {props.t("NEW_CYCLE_EDIT")} 
                </button>
            )
        }

        let textDelete = props.t("NEW_CYCLE_DELETE")

        if(Number(props.status) == 9){

            if(isAuthor()){
                buttons.push(
                    <Button key="buttons-3" onClick={onConfimBack} className="btn text-warning btn-sm" load={laodingback} >
                        <FontAwesomeIcon icon={faFile} /> {props.t("NEW_CYCLE_BACK_DRAFT")}
                    </Button>
                )
            }

            textDelete = props.t("NEW_CYCLE_DELETE_FOREVER")
        }

        
        if(isAuthor()){
            buttons.push(
                <Button key="buttons-4" onClick={onConfimDelete} className="btn text-danger btn-sm" load={loadingdelete} >
                    <FontAwesomeIcon icon={faTrash} /> {textDelete}
                </Button>
            )
        }

        // let cl = favorite == true || favorite =="true" ? "btn btn-outline-danger btn-sm" : "btn btn-outline-default btn-sm"
        // let color = favorite == true || favorite =="true" ? "red" : "gray"

        // buttons.push(
        //     <Button onClick={onFavorite} className={cl} load={loadingfavorite} >
        //         <FontAwesomeIcon icon={faCrown} color={color} />
        //     </Button>
        // )

        return buttons
    }

    const getShareButton = () => {

        if(!is_public){
            return null
        }

        return(
            <CopyToClipboard text={window.location.href}
                onCopy={() => {
                    setShared(true)
                    setTimeout(()=>{
                        setShared(false)
                    },1000)
                }}>
                    <button className="btn btn-sm text-success"><FontAwesomeIcon icon={faShare} /> {shared ? props.t("CYCLE_SHARED")+ "..." : props.t("CYCLE_SHARE") }</button>
            </CopyToClipboard>
        )
    }

    const getIsPublic = () =>{
        return is_public ? <span><FontAwesomeIcon icon={faGlobe} color="green" /></span> : null
    }

    const getFavorite = () => {
        if( isLogin() && isAuthor() || isLogin() && isSharedByMe() ){
            return <> <Favorite value={favorite} onClick={onFavorite} />  </>
        }
        return null;
    }

    const getLabelRepeat = () =>{
        if( isLogin() && (isAuthor() || isSharedByMe() ) ){
            return <LabelRepeat repetition={Number(props.repetition)} status={Number(props.status)} />
        }
        return null;
    }

    const getInCycle = () =>{
        
        if( isLogin() && (isAuthor() || isSharedByMe() )  ) {
            return <InCycle isRepeated={Number(props.isRepeated)} status={Number(props.status)} /> 
        }
        
        return null
    }


    const getLabelRepetition = () =>{
        if(  isLogin() && (isAuthor() || isSharedByMe() )  ) {
            return <LabelRepetition value={Number(props.repetition)} />
        }
        
        return null;
    }

    if(!cycle_id){
        return null
    }

    const srcImg =  getImageFromBase64(props.cycle.description) || "/preview.png"

    const descriptionClear = getTextFromHtml(props.cycle.description);

    return(
        <>
            <div className="row cycle">
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{ "Learn Cycle " + " | " + props.cycle.name}</title>
                    <meta name='description' content={descriptionClear} />
                    <meta property='og:locale' content='en_US' />
                    <meta property='og:type' content='website' />
                    <meta property='og:title' content={"Learn Cycle " + " | " + props.cycle.name} />
                    <meta property='og:description' content={descriptionClear} />
                    { srcImg ? <meta property='og:image' content={srcImg} /> : null}
                    <meta property='og:url' content={window.location.href} />
                    <meta name='twitter:card' content={srcImg} />
                    <meta name='twitter:site' content='World Best Place' />
                    <meta name='twitter:creator' content='Klevis Cipi' />
                </Helmet>
                <div className="col-md-12 mb-3 mt-3">
                    <h1 style={{overflowWrap: 'break-word'}} className="cycle-title">
                        {getIsPublic()}{ validURL(props.cycle.name) || props.cycle.link ? <a target="_new" href={ props.cycle.link || props.cycle.name }>{props.cycle.name}</a> : props.cycle.name}
                    </h1>
                    {getFavorite()}
                    {getInCycle()}
                    {" "}
                    {getLabelRepeat()}
                    {" "}
                    {getLabelRepetition()} <TagView single  options={getTagFrom(props.cycle.tags)} />
                </div>
                <div className="col-md-12 mb-3">

                    <Author
                        className="author-cycle" 
                        name={props.user.name}
                        subname={props.user.subname}
                        avatar={props.user.avatar}
                        created_at={props.cycle.created_at}
                    />

                    <div className="btn-group pull-right cycle-buttons" role="group" aria-label="Basic outlined">
                        <br />
                        {showButtons()}
                        {getShareButton()}
                    </div>
                </div>
                <div className="col-md-12">
                    <br/>
                </div>
                <div className="col-md-12">
                    <div className="cycle-description">
                        <div className="ql-editor" dangerouslySetInnerHTML={{__html:props.cycle.description}}>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};



CycleView.propTypes = {
    id:PropTypes.number,
    status:PropTypes.number,
    repetition:PropTypes.number,
    onReload:PropTypes.func,
    isRepeated:PropTypes.func,
    setIsedit:PropTypes.func,
    match: PropTypes.object,
	history:PropTypes.object,
    isFavorite: PropTypes.bool,
    user:PropTypes.object,
    cycle:PropTypes.object,
    is_for_learn:PropTypes.oneOfType(PropTypes.bool,PropTypes.string),
    user_original_id:PropTypes.number,
    user_id:PropTypes.number,
    t:PropTypes.object,
    auth:PropTypes.object
}

export default withTranslation()(CycleView);