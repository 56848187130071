import React from 'react'

import PropTypes from 'prop-types';

import _ from 'lodash';

import Api from '../helpers/api.js'

import { withTranslation } from 'react-i18next';

import Pagination from './../components/Paginator.js';

import { isLogin } from "./../utils/index";

import Table from "./../components/Table";
import Cards from './../components/Cards';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faList,faThLarge } from '@fortawesome/free-solid-svg-icons'

import Search from '../components/Search.js';
import Tag from '../components/Tag'

import { setQueryStringParams,getQueryStringParams } from "./../helpers/filters";

import Loading from '../components/Loading';
import { getTagFrom } from '../helpers/index.js';

import Masonry from 'masonry-layout'

const allF = ()=>{
    return getQueryStringParams('filters')
}

class CycleList extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            sources:null,
            fases:null,
            results:null,
            onSearch:"",
            filters:allF(),
            sort:getQueryStringParams('sort') || null,
            page:getQueryStringParams('sort') || null,
            paginationData:null,
            clear:false,
            view: 'grid',
        }

        this.onSort         = this.onSort.bind(this)
        this.getCycles       = this.getCycles.bind(this);
        this.onReload      = _.debounce(this.onReload,200)
        this.onPagination   = this.onPagination.bind(this)
        this.onChangeView   = this.onChangeView.bind(this)
        this.onSearch       = this.onSearch.bind(this)
        this.onTag          = this.onTag.bind(this)
    }

    getTypeCycle(){
        return this.props.match 
            && this.props.match.params 
            && this.props.match.params.type ? this.props.match.params.type : 'prepared'
    }

    getPrevTypeCycle(props){
        return props.match && props.match.params && props.match.params.type ? props.match.params.type : 'prepared'
    }

    getSearchParams(){
        return this.props.location.search    
    }

    getPrevSearchParams(props){
        return props.location.search    
    }

    getFilterValue(key){
        return this.state.filters && this.state.filters[key] ? this.state.filters[key] : null
    }

    componentDidMount(){
        this.getCycles()
    }

    componentDidUpdate(prevProps){

        if( ( this.getTypeCycle() != this.getPrevTypeCycle(prevProps) ) || ( this.getSearchParams() != this.getPrevSearchParams(prevProps) ) ){
            this.onReload()
            setQueryStringParams(null,'page')
            this.setState({page:null})
        }
    }

    onReload(){
        this.getCycles()
    }

    onSearch(value=""){

        const filters = Object.assign({},getQueryStringParams('filters'),{search:value})

        this.setState({
            filters:filters,
            page:null
        })

        setQueryStringParams(filters,'filters')

        this.getCycles()

        if(this.props.onReload) this.props.onReload()
    }
    onTag(value){

        const filters = Object.assign({},getQueryStringParams('filters'),{tags:value})

        this.setState({
            filters:filters,
            page:null
        })

        setQueryStringParams(filters,'filters')

        if(this.props.onReload) this.props.onReload()

        this.onReload()
    }
    onSort(sort){
        let newSort = sort;
        this.setState({sort:newSort,page:null})
        setQueryStringParams(newSort,'sort')
        this.onReload()
    }
    onPagination(page){
        this.setState({page:page})
        setQueryStringParams(page,'page')
        this.onReload()
    }
    getCycles(){

        const filters = getQueryStringParams('filters')
        const sort = getQueryStringParams('sort')
        const page = getQueryStringParams('page')

        this.setState({load:true})

        let getData = {}

        if(sort){
            getData.sort = sort
        }

        if(filters){
            getData.filters = filters
        }

        if(page){
            getData.page = page
        }

        this.setState({load:true})


        try {

            let url = 'cycles/'+this.getTypeCycle()

            if(!isLogin() || this.props.isPublic){
                url = 'cycles/shared'
            }

            Api.get(url,getData).then((response) => {
                this.setState({
                    results:response.result.data,
                    paginationData:{
                        per_page:response.result.per_page,
                        total:response.result.total,
                        current_page:response.result.current_page
                    },
                    load:false
                })

                setTimeout(()=>{
                    new Masonry( '.themasonry', {
                        itemSelector: '.themasonry-cycle',
                        // columnWidth: 200
                    },500);
                })
            })
            .catch(() => {
                this.setState({load:false})
            });
        } catch (error) {
            this.setState({load:false})
        }

    }

    onChangeView(view){
        this.setState({view})
        setQueryStringParams(view,'view')
        if(this.props.onReload) this.props.onReload()
    }

    showPagination(){
        if(this.state.view == "calendar"){
            return false
        }
        return true
    }

    renderDataContent(){
        
        const {results} = this.state
    
        const columns = [
            {
              Header: "Name",
              accessor: "name",
            },
            {
                Header: "Favorite",
                accessor: "isFavorite",
            },
            {
                Header: "Cycles",
                accessor: "repetition",
            },
            
            {
                Header: "Tags",
                accessor: "tags",
            }
        ];

        if( !results || results.length == 0 && this.state.load ){
            return (
                <div className="row">
                    <div className="col-md-12">
                        <div style={{margin:'auto',width:'200px',position:"relative",minHeight:'100px'}}>
                            <Loading />
                        </div>
                    </div>  
                </div>
            )
        }

        if(this.state.view=="table") {
            return <Table columns={columns}  data={results||[]} load={this.state.load}/>
        }

        if(this.state.view=="grid"){
            return <Cards 
                        data={results||[]} 
                        history={this.props.history} 
                        load={this.state.load} 
                        isPublic={this.props.isPublic}
                        auth={this.props.auth}
                        onReload={()=>{
                            window.location.reload()
                        }}
                    />
        }

        return null
    }

    /**
     * @deprecated
     */
    renderViewButtons(){
        if(!isLogin()){
            return null;
        }

        return(
            <div className="col-sm-2 col-md-2 mr-3">
                <div className="btn-group pull-left" role="group" aria-label="Basic example">
                    <button onClick={()=>this.onChangeView('grid')} type="button" className={this.state.view == 'grid' ? "btn  btn-green mb-3" : "btn  btn-outline-secondary mb-3"}><FontAwesomeIcon icon={faThLarge} /></button>
                    <button onClick={()=>this.onChangeView('table')} type="button" className={this.state.view == 'table' ? "btn  btn-green mb-3" : "btn  btn-outline-secondary mb-3"}><FontAwesomeIcon icon={faList} /></button>
                </div>
            </div>
        )
    }

    render(){
        
        const {paginationData} = this.state

        return(
            <div className="row mt-3">

                <div className="col-sm-12 col-md-12">
                    <div className="row">
                        {/* {this.renderViewButtons()} */}
                        <div className="col-sm-12 col-md-5">
                            <Search placeholder={this.props.t("SIDEBAR_SEARCH")} value={this.getFilterValue('search')} onChange={this.onSearch} onClear={this.onSearch} />
                        </div>
                        <div className="col-sm-12 col-md-5 mb-3">
                            <Tag shouldCreate={false} value={getTagFrom(this.getFilterValue('tags'))} onChange={this.onTag} />
                        </div>
                    </div>
                </div>

                <div className="col-md-12 mt-2">
                    {this.renderDataContent()}
                </div>

                <div className="col-md-12 mt-2">
                    {
                        paginationData && this.showPagination() ?
                            <Pagination
                                {...paginationData}
                                disabled={this.state.load}
                                onPaginate = {this.onPagination}
                            />
                        :null
                    }
                </div>
            </div>
        )
    }
}

CycleList.propTypes = {
    location:PropTypes.object,
    match: PropTypes.object,
	history:PropTypes.object,
    onReload:PropTypes.func,
    isPublic:PropTypes.bool,
    t:PropTypes.object,
    auth:PropTypes.object
}

export default withTranslation()(CycleList)