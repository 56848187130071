import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { isLogin ,setRouteTitle } from '../utils';

const PrivateRoute = ({component: Component,name, ...rest}) => {

    let locations = window.location.href
    let pathname = window.location.pathname
    
    setRouteTitle(rest,name)

    return (

        // Show the component only when the user is logged in
        // Otherwise, redirect the user to /signin page
        <Route 
            {...rest} 
            render={props => ( isLogin() && pathname !="/" ? <Component {...props}/> : <Redirect to={"/signin?rel="+locations} /> )}
        />
    );
};

PrivateRoute.propTypes = {
    component:PropTypes.func,
    name:PropTypes.string
}

export default PrivateRoute;