import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { isLogin,setRouteTitle } from '../utils';

const PublicRoute = ({component: Component, restricted,name, ...rest}) => {
    
    setRouteTitle(rest,name)

    return (
        // restricted = false meaning public route
        // restricted = true meaning restricted route
        <Route {...rest} render={props => (
            isLogin() && restricted ?
                <Redirect to="/cycles/prepared" />
            : <Component {...props} />
        )} />
    );
};

PublicRoute.propTypes = {
	component:PropTypes.func,
    restricted:PropTypes.bool,
    name:PropTypes.string
}

export default PublicRoute;