import React,{useState,useCallback,useEffect} from "react";
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave, faFile, faArrowLeft, faCircleNotch, faGlobe, faUserShield } from '@fortawesome/free-solid-svg-icons'

import debounce from "lodash/debounce";

import Tag from "./../components/Tag";

import Editor from "./../components/EditorQuill";

import Button from './../components/Button'

import Notification from './../components/Notification'

import Api from './../helpers/api'

import { getTagFrom } from "./../helpers/index";

import { withTranslation } from 'react-i18next';

const CycleForm =  (props) => {

    // const[isChange,setIsChange] = useState(false)
    const[name,setName] = useState( props.cycle && props.cycle.name || "" )
    const[favorite] = useState(props.favorite||"")
    const[tags,setTags] = useState(getTagFrom(props.cycle && props.cycle.tags)||"")

    const[description,setDescription] = useState(props.cycle && props.cycle.description||"")

    const[loading,setLoading] = useState(false)
    const[loadingdraft,setLoadingDraft] = useState(false)
    const[loadingpublish,setLoadingPublish] = useState(false)
    const[loadingsave,setLoadingJustSave] = useState(false)
    const[loadingrepeat,setLoadingRepeat] = useState(false)

    const onSaved       = props.onSaved
    const cycle_id      = props.cycle && props.cycle.id
    const share_id      = props.id
    const user_original_id = props.user_original_id
    const user_id       = props.user_id
    const repetition    = props.repetition;
    const onReload      = props.onReload
    const is_public     = props.cycle ? props.cycle.is_public : false
    const hyperlink     = props.cycle && props.cycle.link ? props.cycle.link : ""

    const status        = props.status

    const debounceSaveMakeCycleHandler = useCallback(

        debounce(() => {
            let url = ''

            if(props.mode=="edit" || cycle_id){
                url = 'cycles/update/'+cycle_id+"/"+share_id
            }else{
                url = 'cycles/create'
            }

            let values ={
                name,
                description,
                tags,
                status:3,
                repetition:1,
                isFavorite:favorite,
                imageName:'',
                isRepeated:true
            }

            if( Number( repetition ) > 4 ){
                values.isRecycle = true
            }

            try {

                Api.post(url,values).then((response)=>{

                    setLoading(false)

                    if(response.status=="success"){

                        Notification.notify({
                            message: response.message ? response.message : [props.t("NOTIS_SAVED")],
                            type:"success"
                        });

                        if(cycle_id){
                            onReload()
                        }else{
                            onSaved(response.result)
                        }
                        
                    }else{
                        Object.keys(response).map((k)=>{
                            if(response[k] && response[k][0]){
                                Notification.notify({
                                    message:response[k][0],
                                    type:"danger"
                                });
                            }
                        })
                    }
                }).catch(()=>{

                    setLoading(false)

                    Notification.notify({
                        message:props.t("NOTIS_SOMETHING_WRONG"),
                        type:"danger"
                    });
                })
            } catch (error) {

                setLoading(false)

                Notification.notify({
                    message:props.t("NOTIS_SOMETHING_WRONG"),
                    type:"danger"
                });
            }

        }, 1000),
        [
            cycle_id,
            share_id,
            onReload,
            setLoading,
            onSaved,
            name,
            description,
            tags,repetition]
    );

    const debounceJustSaveHandler = useCallback(

        debounce(() => {
            let url = ''

            let values ={
                name,
                description,
                tags,
                isFavorite:favorite,
                imageName:''
            }

            if(props.mode=="edit" || cycle_id){
                url = 'cycles/update/'+cycle_id+"/"+share_id
            }else{
                url = 'cycles/create'
                values.repetition = 0;
                values.status = 1
            }

            if(Number(status) == 0){
                values.status = 1
            }

            try {

                Api.post(url,values).then((response)=>{

                    setLoadingJustSave(false)

                    if(response.status=="success"){

                        Notification.notify({
                            message: response.message ? response.message : "Cycle saved successfullt!",
                            type:"success"
                        });

                        if(!props.id && response.result && response.result.id){
                            setTimeout(()=>{
                                window.location.href = '/cycle/view/'+response.result.cycle.short_link+"/edit"
                             },500)
                        }else{
                            props.onReload()
                        }
                    }else{

                        Object.keys(response).map((k)=>{
                            if(response[k] && response[k][0]){
                                Notification.notify({
                                    message:response[k][0],
                                    type:"danger"
                                });
                            }
                        })
                    }

                }).catch(()=>{
                    setLoadingJustSave(false)
                    
                    Notification.notify({
                        message:props.t("NOTIS_SOMETHING_WRONG"),
                        type:"danger"
                    });
                })
            } catch (error) {

                setLoadingJustSave(false)

                Notification.notify({
                    message:props.t("NOTIS_SOMETHING_WRONG"),
                    type:"danger"
                });
            }

        }, 1000),
        [setLoading,onSaved,name,description,tags,status,cycle_id,share_id]
    );

    const debounceDraftHandler = useCallback(
        debounce(() => {
            let url = ''

            if(props.mode=="edit" || cycle_id){
                url = 'cycles/update/'+cycle_id + "/" + share_id
            }else{
                url = 'cycles/create'
            }

            let values = {
                name,
                description,
                tags,
                status:0,
                repetition:0,
                isFavorite:favorite,
                imageName:'',
            }

            try {

                Api.post(url,values).then((response)=>{

                    setLoadingDraft(false)

                    if(response.status=="success"){

                        Notification.notify({
                            message: response.message ? response.message : props.t("NOTIS_SAVED_DRAFT"),
                            type:"success"
                        });

                        onSaved(response.result)

                        setTimeout(()=>{
                            window.location.href = '/cycle/view/'+props.cycle.short_link
                        },1500)

                    }else{

                        Object.keys(response).map((k)=>{
                            
                            if(response[k] && response[k][0]){
                                Notification.notify({
                                    message:response[k][0],
                                    type:"danger"
                                });
                            }
                        })
                    }

                }).catch(()=>{
                    setLoadingDraft(false)
                    Notification.notify({
                        message:props.t("NOTIS_SOMETHING_WRONG"),
                        type:"danger"
                    });
                })
            } catch (error) {
                setLoadingDraft(false)

                Notification.notify({
                    message:props.t("NOTIS_SOMETHING_WRONG"),
                    type:"danger"
                });
            }

        }, 1000),
        [setLoadingDraft,onSaved,name,description,tags,cycle_id,share_id]
    );

    const debouncePublishHandler = useCallback(
        debounce((is_it_pulic) => {
            let url = ''

            let values ={
                name,
                description,
                tags,
                isFavorite:favorite,
                imageName:'',
                is_public:is_it_pulic
            }

            if(props.mode=="edit" || cycle_id){
                url = 'cycles/update/'+cycle_id+"/"+share_id
            }else{
                url = 'cycles/create'
                values.repetition = 0;
                values.status = 1
            }

            if(Number(status) == 0){
                values.status = 1
            }

            try {

                Api.post(url,values).then((response)=>{

                    setLoadingDraft(false)

                    if(response.status=="success"){

                        Notification.notify({
                            message: response.message ? response.message : props.t("NOTIS_SAVED_PUBLIC"),
                            type:"success"
                        });

                        onSaved(response.result)

                        setTimeout(()=>{
                            window.location.href = '/cycle/view/'+props.cycle.short_link
                        },1500)

                    }else{

                        Object.keys(response).map((k)=>{
                            
                            if(response[k] && response[k][0]){
                                Notification.notify({
                                    message:response[k][0],
                                    type:"danger"
                                });
                            }
                        })
                    }

                }).catch(()=>{
                    setLoadingDraft(false)
                    Notification.notify({
                        message:props.t("NOTIS_SOMETHING_WRONG"),
                        type:"danger"
                    });
                })
            } catch (error) {
                setLoadingDraft(false)

                Notification.notify({
                    message:props.t("NOTIS_SOMETHING_WRONG"),
                    type:"danger"
                });
            }

        }, 1000),
        [setLoadingDraft,onSaved,name,description,tags,cycle_id,share_id]
    );

    const debounceRepeatHandler = useCallback(
        debounce(() => {

            try {
                Api.post('cycles/repeat/'+cycle_id+"/"+share_id,{name,description,tags}).then((response)=>{

                    setLoadingRepeat(false)

                    if(response.status == "success"){

                        Notification.notify({
                            message: response.message ? response.message : [
                                props.t("NOTIS_REPEATED"),
                                props.t("NOTIS_LEAVE")
                            ],
                            type:"success"
                        });

                        // setTimeout(()=>{
                        //     window.location.href = "/cycles/prepared"
                        // },4000)

                        props.onReload()

                    }else{
                        Notification.notify({
                            message:props.t("NOTIS_SOMETHING_WRONG"),
                            type:"danger"
                        });
                    }

                }).catch(()=>{
                    setLoadingRepeat(false)
                    Notification.notify({
                        message:props.t("NOTIS_SOMETHING_WRONG"),
                        type:"danger"
                    });
                })
            } catch (error) {
                setLoadingRepeat(false)
                Notification.notify({
                    message:props.t("NOTIS_SOMETHING_WRONG"),
                    type:"danger"
                });
            }

        }, 1000),
        [setLoadingRepeat,cycle_id,share_id,name,description,tags]
    );
    
    const onSave = useCallback(
        (value) => {
            setLoading(true)
            debounceSaveMakeCycleHandler(value);
        },
        [debounceSaveMakeCycleHandler,setLoading]
    );

    const onDraft = useCallback(
        (value) => {
            setLoadingDraft(true)
            debounceDraftHandler(value);
        },
        [debounceDraftHandler,setLoadingDraft]
    );

    const onRepeat = useCallback(
        (value) => {
            setLoadingRepeat(true)
            debounceRepeatHandler(value);
        },
        [debounceRepeatHandler,setLoadingRepeat]
    );

    const onJustSave = useCallback(
        () => {
            setLoadingJustSave(true)
            debounceJustSaveHandler();
        },
        [debounceJustSaveHandler,setLoadingJustSave]
    );

    const onPublish = useCallback(
        () => {
            setLoadingPublish(true)
            debouncePublishHandler(true);
        },
        [debouncePublishHandler,setLoadingPublish]
    );

    const onPrivate = useCallback(
        () => {
            setLoadingPublish(true)
            debouncePublishHandler(false);
        },
        [debouncePublishHandler,setLoadingPublish]
    );

    const isAuthor = () => {
        if(Number(user_original_id) == Number(user_id)){
            return true;
        }
        return false
    }

    // const startInterval = () => {
    //     if(id && name){
    //         setInterval(() => {
    //             debounceJustSaveHandler(true);
    //             setIsChange(false)
    //         }, 5000);
    //     }
    // }

    const clearIntervalIntervals = () => {
        for(let index = 0; index < 1000; index++) {
            window.clearInterval(index)
        }
    }

    useEffect(() => {

        var lastScrollTop = 0;
        var top = 0

        function scrollListener() {

            var st = window.pageYOffset || document.documentElement.scrollTop; // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"
   
            var clientRectangle = document.getElementById("cycle-editor");

            if(clientRectangle){
                clientRectangle = clientRectangle.getBoundingClientRect()
                if ( clientRectangle && clientRectangle.top < 71 && st > lastScrollTop) {
                    var toolbar =document.getElementsByClassName('ql-toolbar')[0] 
                        toolbar.style.position="fixed";
                        toolbar.style.top="66px";
                        toolbar.style.left="0px";
                        toolbar.style.width="100%";
                        toolbar.style.zIndex="100"
                        top = 1
                }else if(  clientRectangle && clientRectangle.top > 70 && st < lastScrollTop && top==0){
                    toolbar =   document.getElementsByClassName('ql-toolbar')[0] 
                                toolbar.style = {};
                }

                if( clientRectangle && clientRectangle.top > 100 ){
                    top = 0
                }
            }

            lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
         }
         
         window.addEventListener("scroll", scrollListener);

        return () => {
            window.removeEventListener("scroll",scrollListener);
            clearIntervalIntervals()
        };

    },[]);

    const getIsPublishButton = () => {

        let newdescription = description

        if(newdescription){
            newdescription = newdescription.replace(/(<([^>]+)>)/gi, "");
        }

        if(!name || !newdescription){
            return null
        }

        if(share_id){
            if(Number(user_original_id) != Number(user_id)){
                return null;
            }
        }

        if(is_public){
            return(
                <Button className="btn btn-xs btn-warning" load={loadingpublish} onClick={onPrivate}>
                    <FontAwesomeIcon icon={faUserShield} /> {props.t("NEW_CYCLE_PRIVATE")}
                </Button>
            )
        }

        return (
            <Button className="btn btn-xs btn-success" load={loadingpublish} onClick={onPublish}>
                <FontAwesomeIcon icon={faGlobe} /> {props.t("NEW_CYCLE_PUBLISH")}
            </Button>
        )
    }

    if(!isAuthor() && share_id){
        return null;
    }

    return(
        <>
            <div className="row mt-3">
                <div className="col-md-12 mb-3">
                    <input 
                        placeholder={props.t("NEW_CYCLE_TITLE")} 
                        name="name" 
                        onChange={(e)=>setName(e.target.value)} 
                        value={name} 
                        className="form-control form-control-lg"
                    />

                    {
                        hyperlink ? <div style={{
                            fontSize: '11px',
                            position: 'relative',
                            left: '2px',
                            top: '2px'
                        }}>{props.t("NEW_CYCLE_ORIGINAL_LINK")}: <a target="_new" title={hyperlink} href={hyperlink}>{name}</a></div>: null 
                    }
                </div>

                <div className="col-md-12 mb-3">
                    <Tag value={tags} onChange={setTags} shouldCreate={true} />
                </div>

                <div className="col-md-12 mb-3">
                    {getIsPublishButton()}
                </div>
 
                <div id="cycle-editor" className="col-md-12 mb-3">
                    <Editor  onChange={(d)=>{setDescription(d);}} value={description} />
                </div>
            </div>
            <div className="row">
                <div className="col-md-12 mb-3 ">
                    <div className="cycle-btn-groups">
                        {
                            props.id?
                            <a onClick={()=>{
                                props.onSaved(false)
                            }} className="btn btn-warning btn-sm pull-left cycle-btn-back"> <FontAwesomeIcon icon={faArrowLeft} />  {props.t("NEW_CYCLE_BACK")}</a>
                            :null
                        }

                        <div style={{marginRight: '14px' }} className="btn-group btn-group-sm pull-right cycle-btn-actions" role="group" aria-label="Basic example">
                        
                            { 
                                (props.id && (props.isRepeated == "false" || props.isRepeated == false ) && Number(props.status) == 2 &&  Number(props.repetition) < 5 ) ? 
                                    <Button className="btn btn-sm btn-outline-warning" load={loadingrepeat} onClick={onRepeat} disabled={!name?true:false}>
                                        <FontAwesomeIcon icon={faCircleNotch} /> {props.t("NEW_CYCLE_REPEAT")}
                                    </Button> 
                                : null
                            }
                            { 
                                Number(props.status) > 0 ? 
                                    null : 
                                    <Button className="btn btn-sm btn-outline-warning" load={loadingdraft} onClick={onDraft} disabled={!name?true:false}>
                                        <FontAwesomeIcon icon={faFile} /> {props.t("NEW_CYCLE_DRAFT")}
                                    </Button>
                            }
                            { 
                                [0,1,2,3,4,undefined,NaN].includes(Number(props.status)) ? 
                                    <Button className="btn btn-sm btn-outline-secondary" load={loadingsave} onClick={onJustSave} disabled={!name?true:false}>
                                        <FontAwesomeIcon icon={faSave} /> {props.t("NEW_CYCLE_SAVE")}
                                    </Button> : 
                                null
                            }
                            { (props.id && [0,1].includes(Number(props.status)) || ( props.id && Number(props.repetition) > 4 ) ) || !props.id ? 
                                <Button className="btn btn-sm btn-outline-success" load={loading} onClick={onSave} disabled={!name?true:false}>
                                    <FontAwesomeIcon icon={faCircleNotch} /> { Number(props.repetition) > 4 ? props.t("NEW_CYCLE_MAKE_CYCLE_AGAIN") : props.t("NEW_CYCLE_MAKE_CYCLE") }
                                </Button> : null}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

CycleForm.propTypes={
    name:PropTypes.string,
    favorite:PropTypes.bool,
    tags:PropTypes.array,
    description:PropTypes.string,
    id:PropTypes.number,
    repetition:PropTypes.number,
    cycle:PropTypes.object,
    status:PropTypes.number,
    onReload:PropTypes.func,
    onSaved:PropTypes.func,
    mode:PropTypes.string,
    isRepeated:PropTypes.bool,
    is_for_learn:PropTypes.bool,
    user_original_id:PropTypes.number,
    user_id:PropTypes.number,
    cycle_learn_date:PropTypes.string,
    t:PropTypes.object
}

export default withTranslation()(CycleForm)