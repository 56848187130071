import React from 'react'

import Layout from "./../components/Layout";

import CycleForm from "../elements/CycleForm";

const Cycle = (props) => {

    const onSaved = (data) =>{
        window.location.href = window.location.origin + "/cycle/view/"+data.cycle.short_link
    }

    return(
        <Layout container="container container-article" withSidebar={false} {...props}> <br /> <CycleForm {...props} onSaved={onSaved} /> </Layout>
    );

}

export default Cycle;