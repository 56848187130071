import React from 'react'

import PropTypes from 'prop-types';

import Notification from "../components/Notification";
import Layout from "../components/Layout";

import Api from '../helpers/api'

import Loading from '../components/Loading';
class Activated extends React.Component{
    constructor(props){
        super(props)

        this.state={
            loading:true
        }

        this.activate = this.activate.bind(this)
    }

    componentDidMount(){
        this.activate()
    }

    activate(){

        this.setState({loading:true})

        let token = this.props.match.params.token

        try {
            Api.post("authenticate/activate/"+token,{token:token}).then((response)=>{

                if(response.status=="success"){

                    Notification.notify({
                        message:"Account activated successfully",
                        type:"success"
                    });

                    setTimeout(()=>{
                        window.location.href = window.location.origin + "/signin";
                    },1000)

                }else{

                    Notification.notify({
                        message:"Something goes wrong!",
                        type:"danger"
                    });

                    this.setState({loading:false})
                }

            }).catch(()=>{

                this.setState({loading:false})

                Notification.notify({
                    message:"Something goes wrong!",
                    type:"danger"
                });

            })
        } catch (error) {

            this.setState({loading:false})

            Notification.notify({
                message:"Something goes wrong!",
                type:"danger"
            });
        }
    }

    renderContent(){

        if(this.state.loading){
            return (
                <div className="row mt-3 text-center">
                    <div className="col-md-12">
                        <h1>Preparing your account...</h1>
                        <div style={{margin:'auto',width:'200px',position:"relative",minHeight:'100px'}}>
                            <Loading />
                        </div>
                    </div>  
                </div>
            )
        }

        return (
            <div className="row mt-3 text-center">
                <div className="col-md-12">
                    <p>The link is  no more avaiable or you account has been activated before.</p>
                    <button type="button" className="btn btn-success" onClick={this.activate}>Try again</button>
                </div>  
            </div>
        )
    }

    render(){
        return(
            <Layout withSidebar={false} history={this.props.history}>
                {this.renderContent()}
            </Layout>
        )
    }
}

Activated.propTypes = {
    match: PropTypes.object,
	history:PropTypes.object
}

export default Activated;