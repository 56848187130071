import React from 'react';
import Layout from "./../components/Layout";

const name = "Learn Cycle"
const cards = "cycles"
const dataUpdated = "2021-08-02"
const email = "cipiklevis@gmail.com"

const PrivacyPolicyEn = (props) => {
    return(
        <Layout withSidebar={false} {...props}>
            <div>
                <h1>Privacy Policy</h1>

                <p>Please read this page in conjunction with our <a target="_new" href="/account/terms">terms and conditions</a>.</p>

                <h2>Email</h2>

                <ul>
                    <li>A valid email address is required to sign up for an account.</li>
                    <li>We use your email address to send you important information about {name}
                        and your account, such as a reset link when you forget your password.
                    </li>
                    <li>We will not send you email unrelated to {name}.</li>
                </ul>

                <h2>User Data</h2>

                <ul>
                    <li>When you add {cards} via {name}, we store the card data.</li>
                    <li>This user data is not visible to other users of the service.</li>
                    <li>This data is visible to support staff when investigating problems with the service, and problems you report.</li>
                </ul>

                <h2>Cookies</h2>

                <ul>
                    <li>{name} uses an internet technology called &quot;cookies&quot; to keep you logged in to the website.</li>
                    <li>The cookies allow us to associate the actions you take with the correct account.</li>
                    <li>The logout option on {name} will allow you to remove your login cookie.</li>
                    <li>{name} do not use any tracking website at the moment</li>
                </ul>

                <h3>Collection of informations as part of using {name} integrations service</h3>
                <ul>
                    <li>We save just the url (hyperlink) and title of browser tab when use the service of extension like `{name} Google Extension`, and data it is the same as you create it on {name} dashboard.</li>
                    <li>At the moment we do not collect any other data except `title` and `url` (hyperlink) from {name} integrations service</li>
                </ul>

                <h2>Logs</h2>

                <ul>
                    <li>All requests made to {name}, whether they be through a web browser are logged.</li>
                    <li>At the moment we do not record any informations like ip or user agents, but {name} can store it in the future.</li>
                </ul>

                <h2>Locations</h2>

                <ul>
                    <li>We use servers and cloud services located inside secure data centers in the EU to store your data.</li>
                    <li>We use a cloud provider located in the UE to send you emails related to your account.</li>
                    <li>Support staff may store a temporary copy of your user data on secure local machines to reproduce a problem you report.</li>
                    <li>A copy of a subset of {name} metadata is stored locally for internal testing purposes. We remove email addresses from the data.</li>
                </ul>

                <h2>Third Party Sharing</h2>

                <ul>
                    <li>
                        The cycles and associated media files you add to {name} are private by default. We will only make them available to the public if you explicitly share/publish them.
                    </li>
                    <li>
                        {name} makes use of secure third party data centers and cloud services in order to store and process your data, 
                        and to send you emails related to your account.
                    </li>
                    <li>
                        We may share your data with law enforcement authorities when required to do so by law.
                    </li>
                    <li>
                        We may extract the review history of your cycles to share with others for academic research purposes. 
                        This will not include the contents of your cycles (such as text or media files), 
                        and the data will not include any information that would personally identify you.
                    </li>
                    <li>
                        When you post a public support request, we may need to paste an excerpt from your user data for communication purposes 
                        (such as "I had a look at the 'to study' card"). If you do not wish such excerpts to be made public, 
                        please mark your ticket as private when writing to us.
                    </li>
                    <li>
                        As cycles and add-ons are contributed by other {name} users,
                        they may contain links to third party servers not under our control. 
                        This includes any images embedded in the deck or add-on descriptions - when you view a description on {name}, 
                        your browser will fetch any images that are hosted on remote sites, and those remote sites will learn of your IP address, 
                        and may send a tracking cookie when an image is fetched from them.
                    </li>
                </ul>

                <h2>Data Retention</h2>
                <ul>
                    <li>We will retain your user data while you continue to use the service.</li>
                    <li>If you wish to remove your user data, you can delete your {name} account from the Account section of the website.</li>
                    <li>If you delete your account, we will delete your user data.</li>
                    <li>Some of your user data may remain in backups and logs when your account is deleted. This data will automatically expire within a month.</li>
                    <li>If an email we sent to you previously bounced or was marked as spam, we will retain a record of this so we can avoid sending to that address again.</li>
                    <li>Any posts you made to the support site will need to be removed separately.</li>
                    <li>We will retain information when legally required to do so.</li>
                </ul>

                <h2>Keeping Data Safe</h2>

                <ul>
                    <li>We endeavour to keep your data safe by following best practices such as encrypting your data in transit, keeping machines up to date, and maintaining backups.</li>
                    <li>No system can ever be completely secure, so we strongly recommend you store a local copy of your user data. At the moment {name} dosen&apos;t have an export system.</li>
                    <li>As your password secures your account, please ensure you use a secure password, and don&apos;t use the same password on multiple sites.</li>
                </ul>

                <h2>Lawful Basis</h2>

                <p>Data processing laws require us to state the lawful basis we use for processing your data.</p>

                <ul>
                    <li>Data you provide to us such as your email address and {cards} content is necessary for providing the service to you (servicing a contract).</li>
                    <li>Statistics that we collect from your data are used to improve the service, and for research (servicing a contract, and legitimate interests).</li>
                    <li>Access logs are used for diagnostic and analytical purposes, and for detecting misuse of the service (servicing a contract, and legitimate interests).</li>
                </ul>

                <h2>Changes</h2>

                <p>If significant changes to this policy are made, we will notify you via
                    a message when visiting the website, a message when you use the synchronization feature, or
                    via email.</p>

                <h2>Transfer</h2>

                <p>In the event of a company acquisition, restructure, or bankruptcy, we may
                transfer your data to a different legal entity. The new legal entity shall
                respect your privacy in the same way, and notify you if
                they wish to use your data in a way not described here.</p>

                <h2>Contact</h2>
    
                <p>If you have any questions about this privacy policy, please send us an email:<b>{email}</b>.</p>

                <p><i>Last updated {dataUpdated}.</i></p>
            </div>
        </Layout>
    );
}

const PrivacyPolicySq = (props) =>{
    return(
        <Layout withSidebar={false} {...props}>
            <div>
                <h1>Politika e privatësisë</h1>

                <p>Ju lutemi lexoni këtë faqe së bashku <a href="/account/terms">Termat dhe Kushtet</a>.</p>

                <h2>Email</h2>

                <ul>
                    <li>Kërkohet një adresë e vlefshme e-mail për t'u regjistruar në një llogari. </li>
                    <li>Ne përdorim adresën tuaj të postës elektronike për t'ju dërguar informacione të rëndësishme në lidhje me {name}
                         dhe llogarinë tuaj, të tilla si,harrimi i fjalëkalimin tuaj.
                    </li>
                    <li>Ne nuk do t'ju dërgojmë email të palidhur me {name}.</li>
                </ul>

                <h2>Të dhënat e përdoruesit</h2>

                <ul>
                    <li>Kur shtoni {cards} përmes {name}, ne i ruajmë të dhënat e cycle.</li>
                    <li>Titulli,përshkrimi,data e krijimit i një cycle është i dukshëm për përdoruesit e tjerë të shërbimit vetëm kur një cycle është publik.</li>
                    <li>Emër dhe Mbiemër i përdoruesit është i dukshëm për përdoruesit e tjerë të shërbimit vetëm kur një cycle është publik.</li>
                    <li>Këto të dhëna janë të dukshme për stafin mbështetës kur hetojnë problemet me shërbimin dhe problemet që raportoni.</li>
                </ul>

                <h2>Cookies</h2>

                <ul>
                    <li>{name} përdor një teknologji në internet të quajtur &quot; cookie &quot; për t'ju mbajtur të authentikuar në këtë shërbim.</li>
                    <li>Cookies na lejojnë të lidhim veprimet që ju bëni me llogarinë e tuaj të saktë.</li>
                    <li>Opsioni i daljes/logout në {name} do t'ju lejojë të hiqni cookie-n tuaj të hyrjes.</li>
                    <li>{name} nuk përdor asnjë website për tracking në këtë moment</li>
                </ul>

                <h3>Mbledhja e informacionit si pjesë e përdorimit të shërbimit të integrimit {name}</h3>
                <ul>
                    <li>Ne ruajmë vetëm lidhjen (hyperlink) dhe titullin e skedës së shfletuesit kur përdorni integrimin e shtesave si `Learn Cycle Google Extension`, dhe të dhënat janë të njëjta me ato që i krijoni në panelin e mësimit të Ciklit.</li>
                    <li>Për momentin ne nuk mbledhim asnjë të dhënë tjetër përveç `titullit` dhe `lidhjen` (hyperlink) nga shërbimi i integrimit {name}</li>
                </ul>


                <h2>Logs</h2>

                <ul>
                    <li>Regjistrohen të gjitha kërkesat e bëra për {name}, pavarësisht nëse janë përmes një shfletuesi në internet.</li>
                    <li>Për momentin ne nuk regjistrojmë ndonjë informacion si IP ose agjentët e shfletuesi të përdoruesit, por {name} mund t'i ruajë ato në të ardhmen.</li>
                </ul>

                <h2>Vendndodhjet</h2>

                <ul>
                    <li>Ne përdorim servera dhe shërbime cloud të vendosura brenda qendrave të sigurta të të dhënave në BE për të ruajtur të dhënat tuaja.</li>
                    <li>Ne përdorim një ofrues cloud të vendosur në UE për t'ju dërguar email në lidhje me llogarinë tuaj.</li>
                    <li>Stafi ndihmës mund të ruajë një kopje të përkohshme të të dhënave tuaja të përdorimit në makinat e sigurta lokale për të riprodhuar një problem që raportoni.</li>
                    <li>Një kopje e një nëngrupi të meta të dhënave {name} ruhet lokalisht për qëllime të testimit të brendshëm. Ne heqim adresat e postës elektronike nga të dhënat.</li>
                </ul>

                <h2>Ndarja e palës së tretë</h2>

                <ul>
                    <li>
                        Cycles dhe skedarët mediatikë shoqërues që shtoni në {name} janë private si default. 
                        Ne do t'i vëmë ato në dispozicion të publikut vetëm nëse i ndani ose i publikoni në mënyrë të qartë.
                    </li>
                    <li>
                        {name} përdor qendrat të sigurta të të dhënave të palëve të treta dhe shërbimet cloud në mënyrë që të ruajë dhe përpunojë të dhënat tuaja,
                         dhe për t'ju dërguar email në lidhje me llogarinë tuaj.
                    </li>
                    <li>
                        Ne mund t'i ndajmë të dhënat tuaja me autoritetet e zbatimit të ligjit kur kërkohet ta bëjnë këtë me ligj.
                    </li>
                    <li>
                        Ne mund të nxjerrim historikun e rishikimit të cycles tuaja për ta ndarë me të tjerët për qëllime kërkimore akademike.
                        Kjo nuk do të përfshijë përmbajtjen e cycles tuaja (të tilla si teksti ose skedarët mediatikë),
                        dhe të dhënat nuk do të përfshijnë ndonjë informacion që do t'ju identifikonte personalisht.
                    </li>
                    <li>
                        Kur postoni një kërkesë për mbështetje publike, mund të na duhet të ngjisim një fragment nga të dhënat tuaja të përdoruesit për qëllime komunikimi
                         (të tilla si "Unë pashë cycle" për të studiuar "). 
                         Nëse nuk dëshironi që fragmente të tilla të bëhen publike, ju lutemi shënoni biletën tuaj si private kur e krujoni.
                    </li>
                    <li>
                        Nese ne cycle perdoren kontent i cili eshte jashte {name} si iframe apo linke imazhe,
                        ato mund të përmbajnë lidhje me serverat e palëve të treta që nuk janë nën kontrollin tonë.
                        Kjo përfshin çdo imazh të ngulitur në cycle ose përshkrime shtesë.
                        Kur shikoni një përshkrim në {name}, shfletuesi juaj do të marrë çdo imazh që strehohet në faqen e palës se trete 
                        dhe ato faqe do të mësojnë për adresën tuaj IP,
                        dhe mund të dërgojë një cookie ndjekëse kur një imazh merret prej tyre.
                    </li>
                </ul>

                <h2>Ruajtja e të dhënave</h2>
                <ul>
                    <li>Ne do të ruajmë të dhënat tuaja të përdoruesit ndërsa ju vazhdoni të përdorni shërbimin.</li>
                    <li>Nëse dëshironi të hiqni të dhënat tuaja të përdoruesit, mund të fshini llogarinë tuaj {name} nga seksioni i Llogarisë në platoformë.</li>
                    <li>Nëse fshini llogarinë tuaj, ne do të fshijmë të dhënat tuaja të përdoruesit.</li>
                    <li>Çdo postim që keni bërë në faqen e mbështetjes do të duhet të hiqet veçmas.</li>
                    <li>Ne do të mbajmë informacionin kur kërkohet ligjërisht për ta bërë këtë.</li>
                </ul>

                <h2>Mbajtja e të dhënave të sigurta</h2>

                <ul>
                    <li>Ne përpiqemi t'i mbajmë të dhënat tuaja të sigurta duke ndjekur praktikat më të mira të tilla si kriptimi i të dhënave tuaja në tranzit, mbajtja e makinave të azhurnuara dhe mirëmbajtja e kopjeve rezervë.</li>
                    <li>Asnjë sistem nuk mund të jetë kurrë plotësisht i sigurt, prandaj ne ju rekomandojmë që të ruani një kopje lokale të të dhënave tuaja të përdoruesit. Për momentin {name} nuk ka një sistem eksporti.</li>
                    <li>Ndërsa fjalëkalimi juaj siguron llogarinë tuaj, sigurohuni që përdorni një fjalëkalim të sigurt dhe mos përdorni të njëjtin fjalëkalim në shumë site.</li>
                </ul>

                <h2>Baza e Ligjshme</h2>

                <p>Ligjet për përpunimin e të dhënave kërkojnë që ne të shprehim bazën e ligjshme që përdorim për përpunimin e të dhënave tuaja.</p>

                <ul>
                    <li>Të dhënat që na ofroni siç janë adresa juaj e emailit dhe përmbajtja e {cards} janë të nevojshme për sigurimin e shërbimit për ju (servisimi i një kontrate).</li>
                    <li>Statistikat që ne mbledhim nga të dhënat tuaja përdoren për të përmirësuar shërbimin dhe për kërkime (shërbimi i një kontrate dhe interesat e ligjshëm).</li>
                    <li>Regjistrat e hyrjes përdoren për qëllime diagnostikuese dhe analitike, dhe për zbulimin e keqpërdorimit të shërbimit (servisimi i një kontrate dhe interesat e ligjshëm).</li>
                </ul>

                <h2>Ndryshime</h2>

                <p>Nëse bëhen ndryshime të rëndësishme në këtë politikë, ne do t'ju njoftojmë përmes
                     një mesazh kur vizitoni faqen e internetit, një mesazh kur përdorni tiparin e sinkronizimit, ose
                     nëpërmjet e-mail.</p>

                <h2>Transferta</h2>

                <p>Në rast të një blerjeje, ristrukturimi ose falimentimi të një kompanie, ne mund
                 transferoni të dhënat tuaja në një entitet tjetër juridik. Subjekti i ri juridik do të
                 respektoni privatësinë tuaj në të njëjtën mënyrë, dhe ju njoftoj nëse
                 ata dëshirojnë të përdorin të dhënat tuaja në një mënyrë që nuk përshkruhet këtu.</p>

                <h2>Contact</h2>
    
                <p>Nëse keni ndonjë pyetje në lidhje me këtë politikë të privatësisë, ju lutemi na dërgoni një email:<b>{email}</b>.</p>

                <p><i>Ndryshimi i fundit {dataUpdated}.</i></p>
            </div>
        </Layout>
    );
}

const PrivacyPolicy = (props) =>{
    const lng = window.localStorage.getItem('i18nextLng') || "en"
    
    if(lng == "sq"){
        return <PrivacyPolicySq {...props} />
    }
    return <PrivacyPolicyEn {...props} />
}

export default PrivacyPolicy;