import React, { useState } from 'react';
import Brand from '../components/Brand';
import PropTypes from 'prop-types';

import { login } from '../utils';

import Footer from '../components/Footer';
import Button from '../components/Button'

import Api from '../helpers/api'

import Notification from "../components/Notification";

import { NavLink } from 'react-router-dom';
import Header from '../components/Header';

import { getQueryStringParams } from "../helpers/filters";

import { withTranslation } from 'react-i18next';

const SignIn = (props) => {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [load, setLoad] = useState(false);
    const [hasErrorEmail,setHaserroremail] = useState("")
    const [hasErrorPass,setHaserrorepass] = useState("")

    let redirect_after = getQueryStringParams('rel');

    const onLogin = () => {

        setLoad(true)

        Api.post('authenticate/login',{email,password}).then((response)=>{
            setLoad(false)
            if(response.status=="success"){

                Notification.notify({
                    message:props.t("SIGNIN_AUTHENTICATED"),
                    type:"success"
                });

                login(response.api_key);

                if(redirect_after){
                    window.location.href=redirect_after;
                }else{
                    props.history.push('/cycles/prepared');
                }
            }else{
                if(response.email){
                    setHaserroremail(response.email[0])
                    Notification.notify({
                        message:response.email,
                        type:"danger"
                    });
                }

                if(response.password){
                    setHaserrorepass(response.password)
                    Notification.notify({
                        message:response.email[0],
                        type:"danger"
                    });
                }

                if(!response.result){
                    Notification.notify({
                        message:[props.t("SIGNIN_NOTIS_EXIST")],
                        type:"danger"
                    });
                }
            }
        }).catch(()=>{
            setLoad(false)
        })
    }

    const handleKeypress = (e,email_,password_) => {
        if (Number(e.charCode) === 13 && email_ && password_) {
            onLogin();
        }
    }
    
    return (
        <>
            <Header {...props} />
            <main className="signin">
                <form >
                    <Brand />
                    {/* <h1 className="h3 mb-3 fw-normal text-center">Sign in</h1> */}
                    <input onKeyPress={(e)=>handleKeypress(e,email,password)} onChange={(e)=>setEmail(e.target.value)} type="email" id="inputEmail" className={"form-control " + (hasErrorEmail ? "is-invalid" : "")} placeholder={props.t("SIGNIN_EMAIL")} required autoFocus value={email}/>
                    <input onKeyPress={(e)=>handleKeypress(e,email,password)} onChange={(e)=>setPassword(e.target.value)}  type="password" id="inputPassword" className={"form-control " + (hasErrorPass ? "is-invalid" : "")} placeholder={props.t("SIGNIN_PASSWORD")} required />
                    <Button 
                        className="w-100 btn btn-lg btn-primary btn-signin" 
                        load={load} 
                        onClick={onLogin}
                        onKeyPress={handleKeypress}
                    >{props.t("SIGNIN")}</Button>
                    <p className="mb-3 mt-3 text-left">
                        <NavLink to={"/reset"}>{props.t("SIGNIN_FORGOT")}</NavLink>
                    </p>
                    <hr className="mt-4" />
                    <div className="col-12">
                        <p className="mb-0 mr-3">{props.t("SIGNIN_SIGNUP")}
                            <NavLink to={"/signup"}> {props.t("SIGNUP")} </NavLink>
                        </p>
                    </div>
                </form>
              
            </main>
            <Footer />
        </>
    );
};
SignIn.propTypes = {
    match: PropTypes.object,
    history:PropTypes.object,
    t:PropTypes.object
}
export default withTranslation()(SignIn);