import React from 'react';

import PropTypes from 'prop-types';

import {TagView} from './Tag'

import LabelRepetition from './LabelRepetition'
import LabelRepeat,{ InCycle } from './LabelRepeat'

import Favorite from './Favorite'

import {getTagFrom} from '../helpers/index'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faGlobe } from '@fortawesome/free-solid-svg-icons'

import Author from './Author';

import { isLogin, getImageFromBase64} from '../utils';

import { withTranslation } from 'react-i18next';

import { getQueryStringParams } from "./../helpers/filters";

const Cards = (props) =>{

    let allParams = getQueryStringParams()

    console.log("OK allParams",allParams)

    const getIsPublic = () =>{
        let style={
            position:'relative',
            right:'5px'
        }
        return <span style={style}><FontAwesomeIcon icon={faGlobe} color="green" /></span>
    }

    const onClick = (id) =>{
        props.history.push('/cycle/view/'+id)
    }

    const getFooter = (cycle) => {

        if(!cycle.cycle){
            return null
        }

        let tags = cycle.cycle ? getTagFrom(cycle.cycle.tags) : []

        let styleSmall = {
            color: '#80808094',
            fontSize: '16px'
        }

        return <div className="card-footer">
                    {
                        cycle.cycle.is_public == "true" || 
                        cycle.cycle.is_public == true ?
                            getIsPublic()
                        :null
                    }
                    {isLogin() && !props.isPublic ? <Favorite value={cycle.isFavorite} /> : null}
                    {tags.length > 0 ?<small style={styleSmall}> {" | "} </small>: null}
                    <TagView options={tags} onClick={props.onReload} />
                </div>
    }

    const inCycle = (cycle) =>{
        if(!isLogin() ||  props.isPublic) return null
        return <InCycle isRepeated={Number(cycle.isRepeated)} status={Number(cycle.status)} />
    }

    const getLabelRepeat = (cycle) =>{
        if(!isLogin() || props.isPublic) return null
        return <LabelRepeat repetition={Number(cycle.repetition)} status={Number(cycle.status)} />
    }

    const getLabelRepetition = (cycle) =>{
        if(!isLogin() || props.isPublic) return null
        return <LabelRepetition value={cycle.repetition} />
    }

    const getImage = (cycle) =>{

        if(!cycle.cycle){
            return null
        }

        const src = getImageFromBase64(cycle.cycle.description)

        if(!src) return null
        
        return <img style={{cursor:"pointer"}} onClick={()=>onClick(cycle.cycle.short_link)} src={src} className="card-img-top" />
    }

    const getHeader = (cycle) =>{

        let name = cycle.cycle ? cycle.cycle.name: null

        return(
            <h5 
                onClick={()=>onClick(cycle.cycle ? cycle.cycle.short_link : null)} 
                className="card-title in-card cursor-pointer"
                title={name}
            >
                {name}
            </h5>
        )
    }

    return(
        <>
        <div className="row themasonry"  >
            {
                props.data.map((cycle,key)=>{
                    return  <div key={"card-"+key} className="col-sm-6 col-lg-4 mb-4 themasonry-cycle" style={{opacity:props.load?0.2:1}}>
                                <div key={key} className="card  mb-3">
                                    {getImage(cycle)}
                                    <div className="card-body text-secondary">
                                        {getHeader(cycle)}
                                        <Author
                                            className="author-card" 
                                            name={cycle.user.name}
                                            subname={cycle.user.subname}
                                            avatar={cycle.user.avatar}
                                            created_at={cycle.cycle && cycle.cycle.created_at}
                                        />
                                        {inCycle(cycle)} {getLabelRepeat(cycle)} {getLabelRepetition(cycle)}
                                    </div> 
                                    {getFooter(cycle)}
                                </div>
                            </div>
        
                })
            }
        </div>
        {
            props.data.length == 0 ?
                <div className="row">
                    <div className="col-md-12 text-center">
                        <p>{props.t("NO_CYCLE_IN_VIEW")}</p>
                    </div>
                </div>
            : null
        }
        </>
    )
}

Cards.propTypes = {
    data:PropTypes.array,
    history:PropTypes.object,
    load:PropTypes.bool,
    auth:PropTypes.object,
    isPublic:PropTypes.bool,
    t:PropTypes.object,
    onReload:PropTypes.func
}

export default withTranslation()(Cards)