import React, { useState } from 'react';

import PropTypes from 'prop-types';

import Layout from "./../components/Layout";

import CycleList from "./CycleList";
import { isLogin } from '../utils';

const Dashboard = (props) => {

	const [reload,setReload] = useState(false)

	const makeReload = () => {
		setReload(true)
		setTimeout(()=>{
			setReload(false)
		},500)
	}

    return(
		<Layout history={props.history} reload={reload} withSidebar={isLogin()?true:false} >
			<CycleList {...props} onReload={()=>makeReload()} />
		</Layout>
    )
  
}

Dashboard.propTypes = {
	history:PropTypes.object
}

export default Dashboard;