
/**
 * getUrlParams get the params from a urls
 * @param {String} name 
 * @returns string is the value of params on url
 * @example
 *  A url example https://www.google.com/?name=klevis&id=456
 * let name = getUrlParams('name')
 * //assert name == "klevis"
 */
export function getUrlParams(name) {
    return (location.search.split(name + '=')[1] || '').split('&')[0];
}

export function iOSDevice(){
    return !!navigator.platform.match(/iPhone|iPod|iPad/);
}

export function validURL(str) {
    var pattern = new RegExp('^(https?:\\/\\/)?'+ 
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ 
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+
      '(\\?[;&a-z\\d%_.~+=-]*)?'+
      '(\\#[-a-z\\d_]*)?$','i');
    return !!pattern.test(str);
}

export function getBrowserLanguage(){
    let language = "en"
    
    try {
        let lng = navigator.language || navigator.userLanguage;

        let lnge = lng.split("-")

        if(lnge && lnge[0] && (lnge[0]=="en" || lnge[0] == "sq") ){
            language = lnge[0]
        }
    } catch (error) {
        //
    }
    return language
}

export function setHtmlLang(lng){
    let lang = document.getElementsByTagName('html')
    if(lang && lang[0]){
        lang[0].lang = lng
    }
}