import React from 'react';
import {Tooltip as T} from 'react-tippy';
import PropTypes from 'prop-types';

import 'react-tippy/dist/tippy.css';


const Tooltip = (props) =>{

    return(
  
        <T
            // options
            title={props.content}
            position="top"
            trigger="mouseenter"
        >
          {props.component}
        </T>
    )
}

Tooltip.propTypes = {
    content: PropTypes.string,
	component:PropTypes.element
}

export default Tooltip


  
