import React from 'react'
import PropTypes from 'prop-types'
import Paginate from 'react-paginate'
import { withTranslation } from 'react-i18next';

class Pagination extends React.Component {
   constructor(props){
      super(props )
      this.state = {}
      this.onPaginate = this.onPaginate.bind(this)
  }
  getLimitPerPage(){
      return Number(this.props.per_page)
  }
  getTotal(){
      return Number(this.props.total)
  }
  getCurrentPage(){
      return this.props.current_page
  }
  getTotalPages(){
      return Math.ceil(this.getTotal()/this.getLimitPerPage())
  }
  onPaginate(event){
      this.props.onPaginate(event.selected+1)
  }
   render() {

      if( this.getTotal() < this.getLimitPerPage() ){
        return null
     }

      return (
         <div className="paginator">
            <Paginate
               previousLabel={this.props.t("PAGINATION_PREV")}
               previousClassName="page-item"
               nextClassName="page-item"
               previousLinkClassName="page-link"
               nextLinkClassName="page-link"
               pageCount={this.getTotalPages()}
               pageRangeDisplayed={5}
               marginPagesDisplayed={0}
               nextLabel={this.props.t("PAGINATION_NEXT")}
               breakLabel={<a className="page-link" href="#">...</a>}
               breakClassName={'page-item'}
               pageClassName="page-item"
               pageLinkClassName="page-link"
               onPageChange={this.onPaginate}
               containerClassName={'pagination'}
               activeClassName={'active'}
               hrefBuilder={() => {
                  return '#'
               }}
               
               forcePage={this.getCurrentPage()-1}
            />
         </div>
      )
   }
}

Pagination.propTypes = {
   onPaginate: PropTypes.func.isRequired,
   className: PropTypes.string,
   disabled: PropTypes.bool,
   per_page:PropTypes.number,
   total:PropTypes.number,
   current_page:PropTypes.number,
   page:PropTypes.number,
   t:PropTypes.object
}

export default withTranslation()(Pagination)
