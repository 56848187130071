import './assets/sass/index.scss';

import React from 'react';

import Notifications from "./components/Notification";

import { BrowserRouter, Switch, Route } from 'react-router-dom';

import Dashboard from './containers/Dashboard';
import SignIn from './containers/SignIn';
import SignUp from './containers/SignUp';
import PrivateRoute from './containers/PrivateRoute';
import PublicRoute from './containers/PublicRoute';

import Tag from './components/Tag';
import Modal from './components/Modal';
import Cycle from './containers/Cycle'
import CalendarList from './containers/CalendarList'
import NewCycle from './containers/NewCycle'
import Activated from './containers/Activated';
import Error404 from './containers/404';
import Reset from "./containers/Reset";
import ResetThankYuo from "./containers/ResetThankYuo";
import ResetPassword from "./containers/ResetPassword";

import TermsAndConditions from "./elements/TermsAndConditions";
import PrivacyPolicy from "./elements/PrivacyPolicy";
import AboutUS from './containers/About';

import Account from './containers/profile/Account'
import SaveForLater from './elements/SaveForLater';
import Public from './containers/Public';

function App() {

  return (
      <BrowserRouter>

          <Notifications />
          <Switch>

          <PublicRoute restricted={true} component={SignIn} path="/signin" exact name="SignIn" />
          <PublicRoute restricted={true} component={SignUp} path="/signup" exact name="SignUp"/>
          <PublicRoute restricted={false} component={TermsAndConditions} path="/account/terms" exact name="Terms"/>
          <PublicRoute restricted={false} component={PrivacyPolicy} path="/account/privacy" exact  name="Privacy"/>

          <PublicRoute restricted={false} component={AboutUS} path="/aboutus" exact name="About Us" />

          <PublicRoute restricted={false} component={Reset} path="/reset" exact />
          <PublicRoute restricted={false} component={ResetThankYuo} path="/reset/thankyou/:email" exact  name="Thank You"/>

          <PublicRoute restricted={true} component={ResetPassword} path="/account/reset-password/:token" exact name="Reset Password"/>

          <PublicRoute restricted={true} component={Activated} path="/activate/:token" exact  name="Active" />

          <PublicRoute restricted={true} component={Tag} path="/tags" exact />
          <PublicRoute restricted={true} component={Modal} path="/modal" exact />
          
          <PublicRoute restricted={true} component={Error404} path="/404" exact  name="404" />

          <PublicRoute restricted={false} component={Public} path="/" exact name="Public" />

          <PrivateRoute component={Dashboard} path="/cycles/:type" exact name="Cycles"/>

          <PrivateRoute component={CalendarList} path="/calendar" exact  name="Calendar"/>

          <PublicRoute component={Cycle} path="/cycle/view/:id/:edit?" exact name="View" />
          
          <PrivateRoute component={NewCycle} path="/cycle/new" exact name="New" />

          <PrivateRoute component={SaveForLater} path="/cycle/later" exact  name="Later"/>

          <PrivateRoute component={Account} path="/account/profile" exact  name="Profile"/>

          <Route path="*" component={Error404} />
        </Switch>
      </BrowserRouter>
  );
}

export default App;
