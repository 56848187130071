import React from 'react';
import PropTypes from 'prop-types';

const Button = (props) => {

    const classSpinner = "spinner-border spinner-border-sm"

    return (
        <button 
            onKeyPress={props.onKeyPress || null}  
            className={props.className} 
            type={props.type||"button"} 
            disabled = {props.load||props.disabled} onClick={ props.load == false ? props.onClick : null}
        >
            {props.load ? <> <span className={classSpinner} role="status" aria-hidden="true"></span> {props.textLoad||"Loading..."} </> :props.text||props.children}
        </button>
    );
};

Button.propTypes = {
    onKeyPress:PropTypes.func,
    className:PropTypes.string,
    type:PropTypes.string,
    load:PropTypes.bool,
    disabled:PropTypes.bool,
    onClick:PropTypes.func,
    textLoad:PropTypes.string,
    text:PropTypes.string,
    children:PropTypes.oneOfType(PropTypes.element,PropTypes.array)
}

export default Button;