import React, { useState } from 'react';
import Brand from '../components/Brand';
import PropTypes from 'prop-types';

import Footer from '../components/Footer';
import Button from '../components/Button'

import Api from '../helpers/api'

import Notification from "../components/Notification";

import { NavLink } from 'react-router-dom';
import Header from '../components/Header';

import Reaptcha from 'reaptcha';

import { withTranslation } from 'react-i18next';

const Reset = (props) => {

    let key = '6LecW40aAAAAAKZ7qH0aVk3CND0wd46BaG9wCQT-'

    const [email, setEmail] = useState("");
    const [load, setLoad] = useState(false);
    const [hasErrorEmail,setHaserroremail] = useState("")

    const [verified,setVerified] = useState(false)
    const [recaptcha,setRecaptcha] = useState("")
    
    const onVerify = (recaptchaResponse) => {
		setVerified(true)
		setRecaptcha(recaptchaResponse)
	};
	
    const onLogin = (e) => {

        e.preventDefault()

        setLoad(true)

        Api.post('password/reset-request',{email,recaptcha}).then((response)=>{
            setLoad(false)
            if(response.status=="success"){
                Notification.notify({
                    message:props.t("RESET_NOTIS_ACCEPTED"),
                    type:"success"
                });
                props.history.push('/reset/thankyou/'+email);
            }else{
                if(response.email){
                    setHaserroremail(response.email[0])

                    Notification.notify({
                        message:response.email,
                        type:"danger"
                    });
                    
                }else{
                    Notification.notify({
                        message:props.t("RESET_NOTIS_A_EXIST"),
                        type:"danger"
                    });
                }
            }
        }).catch(()=>{
            setLoad(false)
        })
    }
    
    return (
        <>
        <Header {...props} />
            <main className="signin">
                <form onSubmit={onLogin}>
                    <Brand />
                    {/* <h1 className="h3 mb-3 fw-normal text-center">Reset your password</h1> */}
                    <input onChange={(e)=>setEmail(e.target.value)} type="email" id="inputEmail" className={"form-control " + (hasErrorEmail ? "is-invalid" : "")} placeholder={props.t("SIGNUP_EMAIL")} required autoFocus value={email}/>
                    <br/>
                    <Reaptcha sitekey={key} onVerify={onVerify} />
                    {<Button className="w-100 mt-3 btn btn-lg btn-primary btn-signin" load={load} disabled={!verified} type="submit">{props.t("RESET")}</Button>}
                    <hr className="mt-4" />
                    <div className="col-12">
                        <p className="mb-0 mr-3">{props.t("BACK_TO")} 
                            <NavLink to={"/signin"}> {props.t("SIGNIN")} </NavLink>
                        </p>
                    </div>
                </form>
            </main>
            <Footer />
        </>
    );
};


Reset.propTypes = {
    match: PropTypes.object,
    history:PropTypes.object,
    t:PropTypes.object
}

export default withTranslation()(Reset);