import React from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const ActionButton = (props) => {

    return (
        <a onClick={props.onClick||null} className={props.className} style={{cursor:"pointer"}} >
            {props.text||""}  { props.icon ? <FontAwesomeIcon icon={props.icon} size={props.size}/> : null}
        </a>
    );
};

ActionButton.propTypes={
    onClick:PropTypes.func,
    className:PropTypes.string,
    text:PropTypes.string,
    icon:PropTypes.element,
    size:PropTypes.size
}

export default ActionButton;