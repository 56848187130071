import React from 'react';

import PropTypes from "prop-types";

import { NavLink } from 'react-router-dom';

import { withTranslation } from 'react-i18next';

const Footer = (props) => {

    return (
        <footer className="footer mt-auto py-3 bg-white">
            <div className="container-fluid">
                <span style={{marginTop:3}} className="text-muted mr-3 pull-left">&copy; {process.env.REACT_APP_NAME} 2021</span>
                
                <ul className="nav pull-right">
                    <li className="nav-item">
                        <NavLink to="/aboutus" className="nav-link" activeClassName="active">{props.t("FOOTER_ABOUT_US")}</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="/account/terms" className="nav-link" activeClassName="active">{props.t("FOOTER_TERMS_OF_USE")}</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="/account/privacy" className="nav-link" activeClassName="active">{props.t("FOOTER_PRIVACY")}</NavLink>
                    </li>
                </ul>
            </div>
        </footer>
    );
};

Footer.propTypes={
    t:PropTypes.object
}

export default withTranslation()(Footer);