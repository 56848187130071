import React from 'react';

import PropTypes from 'prop-types';

import Layout from "./../components/Layout";

import CycleList from "./CycleList";

const Public = (props) => {

    return(
		<Layout history={props.history}  withSidebar={false} >
			<CycleList {...props} isPublic={true} />
		</Layout>
    )
  
}

Public.propTypes = {
	history:PropTypes.object
}

export default Public;