import qs from 'qs'

 /**
  * Get query string params of single key
  */
 export function getQueryStringParams(key = null) {
    var params = qs.parse(window.location.search, { ignoreQueryPrefix: true })
 
	if(key && !params){
		return null
	}

    if (!params) {
       return {}
    }

	if(key && params && params[key]){
		return params[key]
	}

	if(key && params && !params[key]){
		return null;
	}
 
    return params
 }

export function setQueryStringParams(
      params, 
      key = null
      // , deleteKey=false
   ) {
   if (!window.history.replaceState) {
      return false
   }

   var baseUrl = [
      window.location.protocol,
      '//',
      window.location.host,
      window.location.pathname,
   ].join('')

   var queryStringObject = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
   })

   if (key) {
      queryStringObject = Object.assign(queryStringObject, { [key]: params })
   } else {
      queryStringObject = Object.assign(queryStringObject, params)
   }

   let queryString = qs.stringify(queryStringObject, { addQueryPrefix: true })
   window.history.replaceState({}, '', baseUrl + queryString)
}
