// @flow
import React,{ Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'bootstrap/dist/css/bootstrap.css';

import App from './App';
import reportWebVitals from './reportWebVitals';

import dotenv from 'dotenv'
import dotenvExpand from 'dotenv-expand';
import Loading from './components/Loading';

import './i18n';

var envm = dotenv.config()
dotenvExpand(envm)

if( ["stage","prod"].includes(process.env.REACT_APP_ENV) ){
  console.log("%cWatch out this is danger zone!","color: red; font-size: 25px");
  console.log("%c“In a gentle way you can shake the world.” ― Mahatma Gandhi","color: red; font-size: 25px");
  console.log("%cSignUp to Learn Cycle and learn something new.","color: red; font-size: 25px");
}

ReactDOM.render(
  <React.StrictMode>
     <Suspense fallback={<Loading />}><App /></Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
