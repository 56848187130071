import React from "react";
import Layout from "../../components/Layout";

import PropTypes from 'prop-types';

import Api from '../../helpers/api'

import Loading from '../../components/Loading';
import LangSelector from "../../components/LangSelector.js";

import EditAccount from "./EditAccount";
import DeleteAccount from "./DeleteAccount";

import { withTranslation } from 'react-i18next';

class Account extends React.Component{
    constructor(props){
        super(props)
        this.state = {load:false,profile:null}
        this.getProfile = this.getProfile.bind(this)
    }

    componentDidMount(){
        this.getProfile()
    }

    getProfile(){
        this.setState({load:true})
        
        Api.get('auth').then((response)=>{
            if(response.status=="success"){
                
                let auth = response.result

                this.setState({
                    profile:auth
                })
            }
            this.setState({load:false})
        })
    }

    getNavLinkButton(id,text,active=""){
        return(
            <button
                style={{marginRight:'5px'}}
                id={id+"-tab"} 
                data-bs-toggle="tab" 
                data-bs-target={"#"+id} 
                type="button" 
                role="tab" 
                aria-controls={id} 
                aria-selected="false"
                className={"nav-link "+ active}
            >{text}</button>
        )
    }

    renderContent(){

        if( !this.state.profile && this.state.load ){
            return (
                <div className="row">
                    <div className="col-md-12">
                        <div style={{margin:'auto',width:'200px',position:"relative",minHeight:'100px'}}>
                            <Loading />
                        </div>
                    </div>  
                </div>
            )
        }

        return(
            <div className="row">
                <div className="col-md-12">
                    <nav>
                        <div className="nav nav-tabs" id="nav-tab" role="tablist">
                            {this.getNavLinkButton("nav-profile",this.props.t("ACCOUNT_PROFILE"),true)}
                            {this.getNavLinkButton("nav-account",this.props.t("ACCOUNT_ACCOUNT"))}
                            {this.getNavLinkButton("nav-preferences",this.props.t("ACCOUNT_PREFERENCES"))}
                        </div>
                    </nav>
                    <div className="tab-content" id="nav-tabContent">
                        <div className="tab-pane fade show active" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                            <EditAccount {...this.state.profile} />
                        </div>
                        <div className="tab-pane fade" id="nav-account" role="tabpanel" aria-labelledby="nav-account-tab">
                            <DeleteAccount />
                        </div>

                        <div className="tab-pane fade" id="nav-preferences" role="tabpanel" aria-labelledby="nav-preferences-tab">
                            <div className="row">
                                <div className="col-md-12 mt-3">
                                    <h4>{this.props.t("ACCOUNT_LANGUAGE")}</h4>
                                    <LangSelector value={this.state.profile && this.state.profile.language} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    render(){
        return(
            <Layout withSidebar={false} {...this.props}>
                <br/>
                <br/>
                {this.renderContent()}
            </Layout>
        )
    }
}

Account.propTypes = {
    t:PropTypes.object
}

export default withTranslation()(Account)