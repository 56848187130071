import React,{ useState } from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEraser } from '@fortawesome/free-solid-svg-icons'

const Search = (props) => {

    const[search,setSearch] = useState(props.value||"")

    const onChange = (e) =>{
        setSearch(e.target.value)
        if( props.onChange ) props.onChange(e.target.value)
    }

    const onClear = () =>{
        setSearch("")
        if( props.onChange ) props.onChange()
    }

    // const onSearch = () =>{
    //     if( props.onSearch ) props.onSearch(search)
    // }

    return (
        <div className="row">
            <div className="col">
                <div className="input-group mb-3">
                    <input 
                        onChange={onChange} 
                        value={search} 
                        type="text" 
                        name="search" 
                        className="form-control" 
                        placeholder={props.placeholder||"Search"} 
                        aria-label="Search..." 
                        aria-describedby="button-addon2"
                    />
                    <button onClick={onClear} className="btn btn-orange" type="button" id="button-addon2"><FontAwesomeIcon icon={faEraser} /></button>
                    {/* <button onClick={onSearch} className="btn btn-outline-secondary" type="button" id="button-addon2">Search</button> */}
                </div>
            </div>
        </div>
    );
};

Search.propTypes = {
    value: PropTypes.string,
    onChange:PropTypes.func,
    onSearch:PropTypes.func,
    placeholder:PropTypes.placeholder
}

export default Search;