import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Api from '../helpers/api'
import PropTypes from 'prop-types';
import {setHtmlLang} from '../utils/Client';

const LangSelector = (props) => {
  
	const DEFAULT_LANG = "en"

	const { i18n } = useTranslation();

	const [selectedLang, setSelectedLang] = useState( props.value || window.localStorage.getItem('i18nextLng') || DEFAULT_LANG);

	const changeLanguage = (event) => {
		let lng = event.target.value
		setSelectedLang(lng);
		try{
			Api.post('account/update/language',{
				language:lng,
			})
		} catch (error) {
			//
		}
		i18n.changeLanguage(lng);
		setHtmlLang(lng)
	}
	
	return (
		<div onChange={changeLanguage}>
			<label className="mr-3">
			<input type="radio" value="en" name="language" checked={selectedLang === 'en'} /> English
			</label>
			<br/>
			<label>
			<input type="radio" value="sq" name="language" checked={selectedLang === 'sq'} /> Albanian
			</label>
		</div>
	)
}

LangSelector.propTypes = {
    value:PropTypes.string
}
 
export default LangSelector;