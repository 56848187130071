
import React from 'react';
import Layout from "../components/Layout"
const Error404 = (props) =>{

    return(
        <Layout {...props} withSidebar={false}>
            <br/>
            <br/>
            <br/>
            <div className="mt-30 d-flex justify-content-center align-items-center" id="main">
                <h1 className="display-1 mr-3 pr-3 align-top border-right inline-block align-content-center">404</h1>
                <div className="inline-block align-middle">
                    <h2 className="font-weight-normal lead" id="desc">The page you requested was not found.</h2>
                </div>
            </div>
        </Layout>
    )
}

export default Error404