import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

const LabelRepetition = (props) => {

    let text = null

    if( Number(props.value) == 1 ){
        text = props.t("LABEL_FIRST_TIME")
    }

    if( Number(props.value) == 2 ){
        text = props.t("LABEL_24_H")
    }

    if( Number(props.value) == 3 ){
        text =  props.t("LABEL_ONE_WEEK")
    }

    if( Number(props.value) == 4 ){
        text = props.t("LABEL_ONE_MONTH")
    }

    if( Number(props.value) == 5 ){
        text =  props.t("LABEL_SIX_MONTH")
    }

    if(!text){
        return null;
    }

    return <span className="badge bg-repetition">{text}</span>
}

LabelRepetition.propTypes={
    value:PropTypes.number,
    t:PropTypes.object
}


export default withTranslation()(LabelRepetition)