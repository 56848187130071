import React from 'react';

import PropTypes from 'prop-types';

import { english } from "flatpickr/dist/l10n/default.js"

import { Albanian } from "flatpickr/dist/l10n/sq.js"

/**
 * https://flatpickr.js.org/themes/
 */
import "flatpickr/dist/themes/dark.css";

import Flatpickr from "react-flatpickr";
import { Component } from "react";

class DateTimePicker extends Component {
    constructor() {
        super();

        this.state = {
            date: '09:00'
        };

        this.onChange = this.onChange.bind(this)
    }

    componentDidMount(){}

    onChange(date){
        console.log("Okkkk",date)
        this.setState({ date })
        this.props.onChange(date[0])
    }

    getLocale(){
        if(this.props.lng=="sq"){
            return Albanian
        }
        return english
    }

    render() {
        const { date } = this.state;
        return (
            <div>
                <Flatpickr
                    options={
                        { 
                            locale:this.getLocale(),
                            noCalendar:true,
                            enableTime: true,
                            dateFormat: "H:i",
                            time_24hr: true,
                            defaultDate: "09:00"
                        }
                    }
                    data-enable-time
                    value={date}
                    onChange={this.onChange}
                    className="form-control form-control-md"
                />
            </div>
        );
    }
}

DateTimePicker.propTypes={
    value:PropTypes.string,
    onChange:PropTypes.func,
    lng:PropTypes.string  
}

export default DateTimePicker