import React, { useState } from 'react';
import Brand from '../components/Brand';
import PropTypes from 'prop-types';

import Header from '../components/Header';
import Footer from '../components/Footer';
import Button from '../components/Button'

import Api from '../helpers/api'

import Notification from "../components/Notification";

import { NavLink } from 'react-router-dom';

import Reaptcha from 'reaptcha';
import { getBrowserLanguage } from '../utils/Client';

import { withTranslation } from 'react-i18next';

const SignUp = (props) => {

	let key = '6LecW40aAAAAAKZ7qH0aVk3CND0wd46BaG9wCQT-'

    const [name, setName] = useState("");
    const [subname, setSubname] = useState("");
    const [email, setEmail] = useState("");
    const [terms, setTerms] = useState(false);
    const [password, setPassword] = useState("");
	const [load, setLoad] = useState(false);
	
	const [verified,setVerified] = useState(false)
	const [recaptcha,setRecaptcha] = useState("")

    const [hasErrorName,setHaserrorename] = useState("")
    const [hasErrorSubname,setHaserroresubname] = useState("")
    const [hasErrorEmail,setHaserroremail] = useState("")
    const [hasErrorPassword,setHaserrorepassword] = useState("")
	const [hasErrorTerms,setHaserroreterms] = useState("")
	
	const onVerify = (recaptchaResponse) => {
		setVerified(true)
		setRecaptcha(recaptchaResponse)
	};
	
    const onSignup = (e) => {

		e.preventDefault();

		setLoad(true)

		let language = window.localStorage.getItem('i18nextLng') || getBrowserLanguage();

		Api.post('authenticate/signupWeb',{name,subname,email,password,terms,recaptcha:recaptcha,language}).then((response)=>{
			setLoad(false)
			if(response.status=="success"){
				Notification.notify({
					message:props.t("SIGNUP_NOTIS_SUCCESS"),
					type:"success"
				});
				props.history.push('/signin');
			}else{

				if(response.email){
					setHaserroremail(response.email)
					Notification.notify({message:response.email,type:"danger"});
				}else{
					setHaserroremail("")
				}
				if(response.password){
					setHaserrorepassword(response.password)
					Notification.notify({message:response.password,type:"danger"});
				}else{
					setHaserrorepassword("")
				}
				if(response.name){
					setHaserrorename(response.name)
					Notification.notify({message:response.name,type:"danger"});
				}else{
					setHaserrorename('')
				}
				if(response.terms){
					setHaserroreterms(response.terms)
					Notification.notify({message:response.terms,type:"danger"});
				}else{
					setHaserroreterms('')
				}
				if(response.subname){
					setHaserroresubname(response.subname)
					Notification.notify({message:response.subname,type:"danger"});
				}else{
					setHaserroresubname('')
				}
			}
		}).catch(()=>{
			setLoad(false)
		})
    }
    
    return (
          <>
            <Header {...props} />
            <main className="signup">
                <form onSubmit={onSignup}>
                    <Brand />
                    {/* <h1 className="h1 mb-3 fw-normal text-center">Sign up</h1> */}
                    <input onChange={(e)=>{setName(e.target.value);setHaserrorename('')}} type="text" id="inputName" className={"form-control " + (hasErrorName ? "is-invalid" : "")} placeholder={props.t("SIGNUP_NAME")} required autoFocus value={name}/>
                    <input onChange={(e)=>{setSubname(e.target.value);setHaserroresubname('')}} type="text" id="inputSubname" className={"form-control " + (hasErrorSubname ? "is-invalid" : "")} placeholder={props.t("SIGNUP_SUBNAME")} required autoFocus value={subname}/>
                    <input onChange={(e)=>{setEmail(e.target.value);setHaserroremail("")}} type="email" id="inputEmail" className={"form-control " + (hasErrorEmail ? "is-invalid" : "")} placeholder={props.t("SIGNUP_EMAIL")} required autoFocus value={email}/>
                    <input onChange={(e)=>{setPassword(e.target.value);setHaserrorepassword("")}}  type="password" id="inputPassword" className={"form-control " + (hasErrorPassword ? "is-invalid" : "")} placeholder={props.t("SIGNUP_PASSWORD")} required />
                    <p
                    style={{
                      fontSize:'14px'
                    }}
                    className={hasErrorTerms ? "text-danger" : ""}>
                      <input 
                        type="checkbox" 
                        onChange={(e)=>setTerms(e.target.checked)} 
                        checked={terms}
                        style={{
                          marginEight: '11px',
                          marginTop: '2px',
                          float: 'left',
                          paddingTop: '7px',
                          position: 'relative',
                          top: '2px',
                          marginRight:8
                        }}
                      /> 
                        {props.t("SIGNUP_ACCEPT")}  <NavLink to={"/account/terms"}> {props.t("FOOTER_TERMS_OF_USE")} </NavLink> && <NavLink to={"/account/privacy"}> {props.t("FOOTER_PRIVACY")} </NavLink>
                    </p>

					<Reaptcha sitekey={key} onVerify={onVerify} />

                    {<Button className="w-100 mt-3 btn btn-lg btn-primary btn-signin" load={load} type="submit" disabled={!verified} >{props.t("SIGNUP")}</Button>}
                    <hr className="mt-4" />
                    <div className="col-12">
                        <p className="mb-0 mr-3"> {props.t("SIGNUP_SIGNIN")}  <NavLink to={"/signin"}> {props.t("SIGNIN")} </NavLink> </p>
                    </div>
                </form>
            </main>
            <Footer />
          </>
    );
};

SignUp.propTypes = {
    match: PropTypes.object,
	history:PropTypes.object,
	t:PropTypes.object
}

export default withTranslation()(SignUp);