import React from "react";

import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

import Api from '../../helpers/api'

import Notification from "../../components/Notification";

import Confirm from "../../components/Confirm";

import {logout} from '../../utils/index'

const msgw = () => {
    return Notification.notify({message:"Something is wrong please try again",type:"danger"});
}

class DeleteAccount extends React.Component{

    constructor(props){
        super(props)
        this.state = {}
        this.onConfirmDeleteAccount = this.onConfirmDeleteAccount.bind(this)
        this.onDeleteAccount = this.onDeleteAccount.bind(this)
    }

    onConfirmDeleteAccount(){
        Confirm({
            title: this.props.t("CONFIRM_MESSAGE_ATTENT"),
            message: this.props.t("ACCOUNT_NOTIS_DELETE_ALERT"),
            buttons: [
              {
                label: this.props.t("CONFIRM_BUTTON_DELETE"),
                onClick: () => this.onDeleteAccount(),
                className:"btn-danger"
              },
              {
                label: 'No',
                onClick: () =>{}
              }
            ]
          })
    }

    onDeleteAccount(){
        this.setState({load:true})
        try{
            Api.post('account/delete').then((response)=>{
                if(response.status=="success"){
                    Notification.notify({
                        message: response.message ? response.message : "Data saved successfullt!",
                        type:"success"
                    });
                    logout()
                }else msgw()
                this.setState({load:false})
            }).catch(()=>{
                this.setState({load:false})
                msgw()
            })
        } catch (error) {
            this.setState({load:false})
            msgw()
        }
    }

    render(){
        return(
            <div className="row">
                <div className="col-md-12 mt-3">
                    <h4>{this.props.t("ACCOUNT_DANGER_AREA")}</h4>
                    <p className="fs-5">{this.props.t("ACCOUNT_DANGER_AREA_TEXT")}</p>
                    <button onClick={this.onConfirmDeleteAccount} className="btn btn-danger">{this.props.t("ACCOUNT_DELETE")}</button>
                </div>
            </div>
        )
    }
}

DeleteAccount.propTypes={
    t:PropTypes.object
}

export default withTranslation()(DeleteAccount)