import React from "react";

import PropTypes from 'prop-types';

import Button from '../../components/Button'

import UplodAvatar from "../../components/UploadAvatar";

import { withTranslation } from 'react-i18next';

import Api from '../../helpers/api'

import Notification from "../../components/Notification";

const msgw = () => {
    return Notification.notify({message:"Something is wrong please try again",type:"danger"});
}
class EditAccount extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            name:props.name||"",
            subname:props.subname||"",
            email:props.email||"",
            avatar:props.avatar||"",
            load:false
        }
        this.onChange = this.onChange.bind(this)
        this.onSave = this.onSave.bind(this)
    }
    onChange(e){
        this.setState({[e.target.name]:e.target.value})
    }
    onSave(){
        this.setState({load:true})
        try{
            Api.post('account/update',{
                name:this.state.name,
                subname:this.state.subname    
            }).then((response)=>{
                if(response.status=="success"){
                    Notification.notify({
                        message: response.message ? response.message : this.props.t("ACCOUNT_NOTIS_SAVE_ACCOUNT"),
                        type:"success"
                    });
                    
                    setTimeout(()=>{
                        window.location.reload();
                    },2000)

                }else msgw()
                this.setState({load:false})
            }).catch(()=>{
                this.setState({load:false})
                msgw()
            })
        } catch (error) {
            this.setState({load:false})
            msgw()
        }
    }

    render(){
        return(
            <div className="row">
                <div className="col-md-4 mt-3">
                    <h4>{this.props.t("ACCOUNT_EDIT_YOUR_DATA")}</h4>
                    <input onChange={this.onChange} name="name" type="text" id="inputName" className={"form-control mb-3"} placeholder="Name" required autoFocus value={this.state.name}/>
                    <input onChange={this.onChange} name="subname" type="text" id="inputSubname" className={"form-control mb-3"} placeholder="Subname" required autoFocus value={this.state.subname}/>
                    <input name="email" type="text" id="inputEmail" className={"form-control mb-3"} placeholder="Email" autoFocus disabled value={this.state.email}/>
                    <UplodAvatar name="avatar" path={this.state.avatar} />
                    <br/>
                    <Button 
                        className="btn btn-md btn-success" 
                        load={this.state.load} 
                        onClick={this.onSave}
                    >{this.props.t("ACCOUNT_SAVE")}</Button>
                </div>
            </div>
        )
    }
}

EditAccount.propTypes = {
    name: PropTypes.string,
	subname:PropTypes.string,
    email:PropTypes.string,
    avatar:PropTypes.string,
    t:PropTypes.object
}

export default withTranslation()(EditAccount)