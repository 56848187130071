import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
    faCircleNotch,
    faFile,
    faTrash,
    faCrown,
    faWallet,
    faBrain,
    faExternalLinkAlt,
} from '@fortawesome/free-solid-svg-icons'

import { NavLink } from 'react-router-dom';

import { getQueryStringParams } from "./../helpers/filters";

import qs from "qs";

function getAllParams(){
    return getQueryStringParams()
}

const Sidebar = (props) => {

    const {auth} = props

    const isProPlan = auth && auth.plan === 'pro'

    const isActive = (name,key,returnBool = false) =>{

        let allParams = getQueryStringParams()

        if(name=="filters"){
            if(allParams && allParams.filters && allParams.filters[key]){
                if(returnBool) return true
                
                return "nav-link my-filter active"
            }
            if(returnBool) return false
        
            return "nav-link active"
        }

        if(returnBool) return true
        
        return "nav-link"
    }

    const onClickFilter = (key=null,value=null) => {

        let allParams = getQueryStringParams()

        if(key && value){
            
            var newParams = Object.assign({},allParams)

            if( key && newParams && newParams.filters && newParams.filters[key] ){
                delete newParams.filters[key]
            }else{
                newParams.filters = Object.assign({},newParams.filters,{[key]:value})
            }

            if(Object.keys(newParams).length > 0){
                props.history.push(props.history.location.pathname+"?"+qs.stringify(newParams))
            }else{
                props.history.push(props.history.location.pathname)
            }
        }

        return
    }

    const isMainActive = (match, location) => {

        if(["/","/cycles/prepared","/cycles/prepared/"].includes(location.pathname)){
            return true
        }
        
        return false
    }

    console.log("getAllParams()",getAllParams())

    return(
        <div className="col-md-3 col-lg-2 layout layout-left">
            <nav id="sidebarMenu" className="d-sm-block d-md-block sidebar collapse">
                <div className="position-sticky pt-3" style={{marginTop:'16px'}}>
                    <ul className="nav flex-column">
                        <li className="nav-item">
                            <NavLink  to={"/cycles/all?"+qs.stringify(getAllParams())} className="nav-link my-repetition" activeClassName="active">
                                <FontAwesomeIcon icon={faWallet} /> {props.t("SIDEBAR_ALL")}
                            </NavLink>
                        </li>
                        <li className="nav-item mb-1">
                            <NavLink isActive={isMainActive} to={"/cycles/prepared?"+qs.stringify(getAllParams())} className="nav-link my-repetition" activeClassName="active">
                                <FontAwesomeIcon icon={faCircleNotch} /> {props.t("SIDEBAR_MY_CYCLES")}
                            </NavLink>
                        </li>
                        {
                            isProPlan &&
                            <li className="nav-item mb-1">
                                <NavLink to={"/cycles/ai?"+qs.stringify(getAllParams())} className="nav-link my-repetition" activeClassName="active">
                                    <FontAwesomeIcon icon={faBrain} /> {props.t("SIDEBAR_AI_CYCLES")}
                                </NavLink>
                            </li>
                        }

                    </ul>
                    <h6 className="sidebar-heading d-flex justify-content-between align-items-center mt-4 mb-3 text-muted">
                        <span>{props.t("SIDEBAR_OTHER")}</span>
                    </h6>
                    <ul className="nav flex-column">
                        <li className="nav-item">
                            <NavLink to={"/cycles/draft?"+qs.stringify(getAllParams())} className="nav-link my-repetition" activeClassName="active">
                                <FontAwesomeIcon icon={faFile} /> {props.t("SIDEBAR_DRAFT")}
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to={"/cycles/trash?"+qs.stringify(getAllParams())} className="nav-link my-repetition" activeClassName="active">
                                <FontAwesomeIcon icon={faTrash} /> {props.t("SIDEBAR_TRASH")}
                            </NavLink>
                        </li>
                    </ul>
                    <h6 className="sidebar-heading d-flex justify-content-between align-items-center mt-4 mb-3 text-muted">
                        <span>{props.t("SIDEBAR_FILTERS")}</span>
                    </h6>
                    <ul className="nav flex-column">
                        <li className="nav-item">
                            <a className={isActive('filters','favorite')} aria-current="page"  onClick={()=>onClickFilter('favorite',true)}>
                                <FontAwesomeIcon icon={faCrown} color={isActive('filters','favorite',true) ? "red" : "gray"} /> {props.t("SIDEBAR_FAVORITES")}
                            </a>
                        </li>
                        { 
                            isProPlan && 
                            <li className="nav-item">
                                <a className={isActive('filters','link')} aria-current="page"  onClick={()=>onClickFilter('link',true)}>
                                    <FontAwesomeIcon icon={faExternalLinkAlt} color={isActive('filters','link',true) ? "red" : "gray"} /> {props.t("SIDEBAR_EXTERNAL_LINK")}
                                </a>
                            </li>
                        }
                    </ul>
                </div>
            </nav>

            <nav id="sidebarMenuMobile" className="d-sm-block d-md-block sidebar collapse">
                <div className="" style={{marginTop:'16px'}}>
                    <ul className="">
                        <li className="nav-item">
                            <NavLink to={"/cycles/all?"+qs.stringify(getAllParams())} className="nav-link my-repetition" activeClassName="active">
                                <FontAwesomeIcon icon={faWallet} />
                                <small>{props.t("SIDEBAR_ALL")}</small>
                            </NavLink>
                        </li>
                        <li className="nav-item mb-1">
                            <NavLink isActive={isMainActive} to={"/cycles/prepared?"+qs.stringify(getAllParams())} className="nav-link my-repetition" activeClassName="active">
                                <FontAwesomeIcon icon={faCircleNotch} />
                                <small>{props.t("SIDEBAR_MY_CYCLES")}</small>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to={"/cycles/draft?"+qs.stringify(getAllParams())} className="nav-link my-repetition" activeClassName="active">
                                <FontAwesomeIcon icon={faFile} />
                                <small>{props.t("SIDEBAR_DRAFT")}</small>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to={"/cycles/trash?"+qs.stringify(getAllParams())} className="nav-link my-repetition" activeClassName="active">
                                <FontAwesomeIcon icon={faTrash} />
                                <small>{props.t("SIDEBAR_TRASH")}</small>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <a className={isActive('filters','favorite')} aria-current="page"  onClick={()=>onClickFilter('favorite',true)}>
                                <FontAwesomeIcon icon={faCrown} color={isActive('filters','favorite',true) ? "red" : "gray"} />
                            </a>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    )
}

Sidebar.propTypes = {
    auth: PropTypes.object,
    history: PropTypes.object,
    t:PropTypes.object
}

export default withTranslation()(Sidebar)