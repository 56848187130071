import qs from 'qs'
import { getEnvApiVersion, getEnvDomain, getToken } from "./../utils/index";

// #if DEV
// return "http://local-space.local:8000/\(self.version)/"
// #elseif STAGE
//     return "https://api-stage.learncycle.space/\(self.version)/"
// #elseif PROD
//     print("OK THIS IS PRODUCTION")
//     return "https://api.learncycle.space/\(self.version)/"
// #else
//     return "http://local-space.local:8000/\(self.version)/"
// #endif

const DS = "/"

const Api = {
    DS:DS,
    getDomain:function() {
        return getEnvDomain()
    },
    getVersion:function(){
        return getEnvApiVersion()
    },
    getRootEndpoint:function() {
        return  this.getDomain() + DS + this.getVersion() + DS
    },
    getHeaders: function() {
        let h = {
            "Content-Type": "application/x-www-form-urlencoded" //'application/json'
        }
        
        if(getToken()){
            h["Authorization"] = "Bearer "+getToken()
        }
        return h
    },
   endpoint: function(request = null) {
        let url = ''
        switch (request) {
            default:
                url = this.getRootEndpoint() + request
            break
        }
        return url
   },
   get: function(request, data = null) {
        let url = this.endpoint(request)

        if(data){
            url = url+"?&"+qs.stringify(data)
        }

        return fetch(url, {
            method: "GET",
            mode:"cors",
            headers: this.getHeaders(),
        }).then(response => response.json())
   },
    post: function(request, data = null) {
        let url = this.endpoint(request)
        return fetch(url, {
            method: "POST",
            mode:'cors',
            headers: this.getHeaders(),
            body: qs.stringify(data)
        }).then(response => response.json())
    },
    delete: function(request, data = null) {
        let url = this.endpoint(request)
        return fetch(url, {
            method: "DELETE",
            headers: this.getHeaders(),
            body: qs.stringify(data)
        }).then(response => response.json())
    },
    put: function(request, data = null) {
        let url = this.endpoint(request)
        return fetch(url, {
            method: "PUT",
            headers: this.getHeaders(),
            body: qs.stringify(data)
        }).then(response => response.json())
    },
    putFile: function(
        request = '', 
        data = null
        // config = null
    ) {
        request = 'file/' + request
        let url = this.endpoint(request)
        return fetch(url, {
            method: "POST",
            headers: {
                'Authorization':this.getHeaders().Authorization
            },
            body: data
        }).then(response => response.json())
    },
}


export default Api