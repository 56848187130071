import React from 'react'

import PropTypes from 'prop-types';

import Layout from "./../components/Layout";

import Api from '../helpers/api'


import Notification from "../components/Notification";

import CycleForm from "../elements/CycleForm";

import Loading from '../components/Loading';

import { withTranslation } from 'react-i18next';

import CycleView from "../containers/CycleView";
import { isLogin } from '../utils';

class Cycle extends React.Component{
    constructor(props){
        super(props)

        this.state={
            isEdit: false,
            cycle:null,
            load:false
        }
        this.setIsedit = this.setIsedit.bind(this)
        this.getCycleRequest = this.getCycleRequest.bind(this)
    }

    getCycleRequest(){
        try {

            let url = 'cycles/get/'+this.props.match.params.id

            if(!isLogin() || this.props.match.params.share && this.props.match.params.share=="public"){
                url = 'cycles/shared/'+this.props.match.params.id
            }

            Api.get(url).then((response) => {

                let r = response.result;
                
                if(r){
                    this.setState({
                        cycle:r,
                        load:false
                    })
                }

                if( !response.result ){
                    Notification.notify({
                        message:[this.props.t("NOTIS_AT_MOMENT_NOT_ACCESS"),this.props.t("NOTIS_UNTIL_PREPARED")],
                        type:"warning"
                    });

                    setTimeout(()=>{
                        this.props.history.push("/cycles/prepared")
                    },3000)
                }
            })
            .catch(() => {
                this.setState({load:false})
            });
        } catch (error) {
            console.log(error,"error")
            this.setState({load:false})
        }
    }

    componentDidMount(){

        if(this.props.match.params && this.props.match.params.edit && isLogin() ){
            this.setState({isEdit:true})
        }

        this.getCycleRequest()
    }
    setIsedit(state){
        this.setState({isEdit:state})
    }

    renderView(){

        if(this.state.cycle && this.state.cycle.id ){
            return <CycleView {...this.state.cycle}  setIsedit={this.setIsedit} onReload={this.getCycleRequest}/>
        }

        return (
            <div className="row">
                <div className="col-md-12">
                    <div style={{margin:'auto',marginTop: '10%',width:'200px',position:"relative",minHeight:'100px'}}>
                        <Loading />
                    </div>
                </div>  
            </div>
        )
    }

    renderCycleForm(){

        if(!this.state.cycle){
            return null
        }

        return <CycleForm 
                    history={this.props.history} 
                    {...this.state.cycle} 
                    onSaved={(ok)=>this.setIsedit(ok?true:false)} 
                    onReload={this.getCycleRequest}
                />
    }

    render(){
        const{ isEdit } = this.state
        return(
            <Layout 
                {...this.props} 
                container="container container-article" withSidebar={false}>
                    { !isEdit 
                        ? this.renderView()
                        : this.renderCycleForm() }</Layout>
        );
    }
}

Cycle.propTypes = {
    match: PropTypes.object,
	history:PropTypes.object,
    id:PropTypes.number,
    t:PropTypes.object
    // isFavorite: PropTypes.bool,
}

export default withTranslation()(Cycle);