export const TOKEN_KEY = 'jwt';
export const EXDAY = 2
export const EXDAY_DELETE = -2
export const ENV_LOCAL="local"
export const ENV_PRODUCTION="production"
export const ENV_STAGE="stage"
export const DS="/"

export const login = (token) => {
    setCookie(TOKEN_KEY,token,EXDAY);
}

export const logout = () => {
    setCookie(TOKEN_KEY,"",EXDAY_DELETE);
}

export const isLogin = () => {
    return typeof checkCookie() !="undefined" && checkCookie() ? true : false;
}

export const getToken = () => {
    return getCookie(TOKEN_KEY);
}

export const getEnv = () => {
    return process.env.REACT_APP_ENV
}

export const getEnvDomain = () => {
    return process.env.REACT_APP_URL
}

export const getEnvApiVersion = () => {
    return process.env.REACT_APP_VERSION
}

export const getAvatarPath = () =>{
    return getEnvDomain()+DS+"avatar"+DS
}

export const setCookie = (cname, cvalue, exdays) => {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires="+d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}
  
export const getCookie = (cname) => {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for(var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
        c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
        }
    }
    return "";
}
  
export const checkCookie = () => {
    var token_saved = getCookie(TOKEN_KEY);
    if (token_saved != "") {
        return true;
    } else {
        return false
    }
}

export function isScriptLoaded(src){
    return document.querySelector('script[src="' + src + '"]') ? true : false;
}

export function setRouteTitle(rest,name){
    let n = name

    if(rest.computedMatch && rest.computedMatch.params.type && rest.computedMatch.path.match(/cycles\//g)){
        n = name +" "+rest.computedMatch.params.type;
    }

    window.sessionStorage.setItem("cycle_route_name",n)
}

export function getImageFromBase64(description){

    if(!description){
        return null
    }

    let regex = /(data:image[^;]+;base64[^"]+)/i
    let base64src = description.match(regex)
    if(!base64src) return ""

    let src = base64src[0] ? base64src[0] : ""
    if(!src) return ""
    return src
}

export function getTextFromHtml(description,length=100){
    let htmlString = description||""
    let plainText = htmlString.replace(/<[^>]+>/g, ' ');
    if(!plainText){
        return description
    }
    return plainText.substring(0,length)||plainText
}