import React from 'react';

import * as dates from 'date-arithmetic'

import PropTypes from 'prop-types';

import { Calendar, Views, Navigate, momentLocalizer } from 'react-big-calendar'

import Api from '../helpers/api'

import Confirm from "../components/Confirm";

import TimeGrid from 'react-big-calendar/lib/TimeGrid'

import moment from 'moment'

import { validURL } from "../utils/Client";

import { withTranslation } from 'react-i18next';

import "react-big-calendar/lib/css/react-big-calendar.css";

const localizer = momentLocalizer(moment);

class TheWeek extends React.Component {
    render() {
        console.log(this.props)
      let { date } = this.props
      let range = TheWeek.range(date)
  
      return <TimeGrid {...this.props} range={range} eventOffset={15} />
    }
}

TheWeek.propTypes = {
    date:PropTypes.string
}

TheWeek.range = date => {
    let start = date
    let end = dates.add(start, 6, 'day')
  
    let current = start
    let range = []
  
    while (dates.lte(current, end, 'day')) {
      range.push(current)
      current = dates.add(current, 1, 'day')
    }
  
    return range
}
  
TheWeek.navigate = (date, action) => {
    switch (action) {
        case Navigate.PREVIOUS:
        return dates.add(date, -7, 'day')

        case Navigate.NEXT:
        return dates.add(date, 7, 'day')

        default:
        return date
    }
}
  
TheWeek.title = date => {
    return `My learn week: ${date.toLocaleDateString()}`
}

const MyCalendar = (props) => {

    const prepareData = (thedata) => {
        let data2 = []
        thedata.map((data)=>{
            data.start = moment(data.cycle_learn_date).toDate()
            data.end = moment(data.cycle_learn_date).toDate()
            data.title = data.cycle.name
            data2.push(data)
        })

        return data2
    }

    const events = prepareData(props.data);

    return(
        <div style={props.load?{opacity:"0.5"}:{opacity:1}}>
            <Calendar
                popup
                selectable
                defaultDate={new Date()}
                defaultView={Views.MONTH}
                localizer={localizer}
                events={events}
                startAccessor="start"
                endAccessor="end"
                style={{ height: "120vh" }}
                messages={{
                        allDay: props.t("CALENDAR_ALL_DAY"),
                        week: props.t("CALENDAR_ALL_WEEK"),
                        work_week: props.t("CALENDAR_ALL_WORK_WEEK"),
                        day: props.t("CALENDAR_DAY"),
                        month: props.t("CALENDAR_MONTH"),
                        previous: props.t("CALENDAR_PREVIOUS"),
                        next: props.t("CALENDAR_NEXT"),
                        tomorrow:props.t("CALENDAR_TOMORROW"), 
                        today: props.t("CALENDAR_TODAY"),
                        agenda: props.t("CALENDAR_AGENDA"),
                }}
                onSelectEvent={event => {
                    Confirm({
                        title:props.t("CALENDAR_ATTENTION"),
                        message:(
                            <div style={{ overflowWrap:'break-word'}}>
                                {props.t("CALENDAR_INCLUDED_CYCLE")}
                                <br/>
                                <b>{props.t("CALENDAR_NAME")}:</b> 
                                    { validURL(event.cycle.name) ? <a href={event.cycle.name}>{event.cycle.name}</a> : event.cycle.name }
                            </div>
                        ),
                        buttons: [
                            {
                                label: props.t("CALENDAR_BUTTON_START"),
                                onClick: () => {

                                    Api.post('cycles/startlearning/'+event.cycle.id+"/"+event.id,{
                                        cycle_learn_date:null,
                                        is_for_learn:true,
                                    }).then((response)=>{

                                        if(response.status == "success"){
                                            props.history.push("/cycle/view/"+event.cycle.short_link)
                                        }
                        
                                    }).catch(()=>{})
                                },
                                className:"btn-success"
                            },
                            {
                                label: props.t("CALENDAR_BUTTON_LATER"),
                                onClick: () =>{
                
                                }
                            }
                        ]
                    })
                }}
                onSelectSlot={({start,end})=>{
                    props.onSelectSlot(start,end)
                }}
                onKeyPressEvent={
                    (event)=>{
                        console.log(event,"event");
                    }
                }
                onNavigate={
                    (date,view,action)=>{
                        props.onNavigate(date,view,action)
                    }
                }
                views={{ month: true, week: true, day:true }}
            />
            <br/>
        </div>
    )
};

MyCalendar.propTypes = {
    history:PropTypes.object,
    data:PropTypes.array,
    onNavigate:PropTypes.func,
    onSelectSlot:PropTypes.func,
    load:PropTypes.bool,
    t:PropTypes.object
}

export default withTranslation()(MyCalendar)