import React from 'react';
import PropTypes from 'prop-types';
import Avatar from './Avatar.js';
import {ENV_LOCAL, getEnv,getAvatarPath} from './../utils/index'
import moment from "moment";

const Author = (props) => {

    const getUser = () => {
        return <> <span className="author-fullname">{ props.name + " " + props.subname}</span></>
    }

    const getAvatar = () => {

        let path = getAvatarPath() + props.avatar

        if(getEnv()==ENV_LOCAL){
            path = "/assets/img/avatar_fake.jpeg";
        }
        return <> <Avatar text={ props.name + " " + props.subname} path={props.avatar?path:null}/> </>
    }

    const getCreatedTime = () =>{
        return <span className="author-created">{ moment(props.created_at).format('lll')}</span>
    }

    return (
        <div className={"row author " + props.className}>
            <div className="col-1">
                {getAvatar()}
            </div>

            <div className="col-10">
                <div className="row">
                    <div className="col-12">
                        {getUser()}
                    </div>
                    <div className="col-12">
                        {getCreatedTime()}
                    </div>
                </div>
            </div>
        </div>
    );
};

Author.propTypes = {
    className:PropTypes.className,
    subname:PropTypes.string,
    name: PropTypes.string,
    avatar:PropTypes.string,
    created_at: PropTypes.string
}

export default Author;