import React, { useState } from 'react';

import { withTranslation } from 'react-i18next';

import PropTypes from 'prop-types';

import { 
	logout,
	isLogin, 
	// getAvatarPath, 
	// getEnv,
	// ENV_LOCAL
} from './../utils/index'

// import Avatar from './Avatar';

import { getBrowserLanguage,setHtmlLang } from './../utils/Client'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPowerOff,faPlus,faSignInAlt } from '@fortawesome/free-solid-svg-icons'

import ActionButton from "./../elements/ActionButton";

import logo from "./../assets/images/llogo.png"

import { useTranslation } from 'react-i18next';


const Header = (props) => {

	const { i18n } = useTranslation();

	const [togler,setTogler] = useState(false);

	const [language,setLanguage] = useState( window.localStorage.getItem('i18nextLng') || getBrowserLanguage() );

	const getName = () => {
		return props.auth && props.auth.id && props.auth.name ? props.auth.name : null
	}

	const getSubName = () => {
		return props.auth && props.auth.id && props.auth.subname ? props.auth.subname : null
	}

	// const getAvatar = () => {
	// 	return props.auth && props.auth.id && props.auth.avatar ? props.auth.avatar : null
	// }
	
	const onLogout = () => {
		props.history.push("/signin")
		logout()
	}

	const setLanguageNonPublic = () =>{
		let lng = !language || language =="en" ? "sq": "en"
		setLanguage(lng)
		i18n.changeLanguage(lng);
		setHtmlLang(lng)
	}

	const onClickTogler = () => {
		setTogler(!togler)
	}

	const getActiveClassLink = (code,className) =>{
		if(props.history){
			if(code == props.history.location.pathname){
				return className
			}
		}
		return ""
	}

	const getAddButton = () => {
				
		if(!isLogin()){
			return null;
		}
		
		return(
			<ul className="navbar-nav me-auto mb-2 mb-lg-0">
				<li className="nav-item active ml-2">
					<ActionButton icon={faPlus}  className={"nav-link text-success " + getActiveClassLink("/cycle/new","active-success")} onClick={()=>props.history.push('/cycle/new')} text={props.t("HEADER_WRITE")} />
				</li>
				{/* <li className="nav-item active ml-2">
					<ActionButton icon={faPlus}  className={"nav-link text-danger " + getActiveClassLink("/cycle/later","active-danger")} onClick={()=>props.history.push('/cycle/later')} text={props.t("HEADER_SAVE_FOR_LATER")}  />
				</li> */}
			</ul>
		)
	}

	const getLogOutButton = () => {

		if(!isLogin()){
			return null;
		}

		let name = getName() || ""

		let subname = getSubName() || ""

		// let avatar = getAvatar() || ""

		// const getAvatarImg = () => {

		// 	let path = getAvatarPath() + avatar
	
		// 	if(getEnv()==ENV_LOCAL){
		// 		path = "https://mdbcdn.b-cdn.net/img/new/avatars/2.jpg"
		// 	}

		// 	return <> <Avatar text={ name + " " + subname} path={avatar?path:null}/> </>
		// }

		return(
			<ul className="navbar-nav px-3">
				<li className="nav-item active ml-2">
					<ActionButton  
						className={"nav-link text-success " + getActiveClassLink("/cycles/all","active-success") } 
						onClick={()=>props.history.push('/cycles/all')} 
						text={props.t("HEADER_ALL_CYCLES")}
					/>
				</li>
				<li className="nav-item active ml-2">
					<ActionButton 
						className={"nav-link text-success " + getActiveClassLink("/","active-success")}
						onClick={()=>props.history.push('/')} 
						text={props.t("HEADER_PUBLIC")} 
					/>
				</li>
				<li className="nav-item active ml-2">
					<ActionButton  
						className={"nav-link text-danger "  + getActiveClassLink("/calendar","active-danger") }
						onClick={()=>props.history.push('/calendar')} 
						text={props.t("HEADER_SAVED_FOR_LATER")} 
					/>
				</li>
				<li className="nav-item text-nowrap nav-bar-fullname" style={{paddingLeft:30}}>
					<a className="nav-link" href="#" onClick={()=>props.history.push('/account/profile')}><b>{name} {subname}</b></a>
				</li>
				<li className="nav-item text-nowrap">
					<a className="nav-link text-danger" href="#" onClick={onLogout}><FontAwesomeIcon icon={faPowerOff} /></a>
				</li>
			</ul>
		)
	}

	const getLoginButton = () =>{

		if(isLogin()){
			return null;
		}

		return(
			<>
				<ul className="navbar-nav me-auto">
					<li className="nav-item text-nowrap">
						<a className="nav-link" href="#" onClick={setLanguageNonPublic}>  {language == "en"? "AL" : "EN"} </a>
					</li>
				</ul>
				<ul className="navbar-nav">
					<li className="nav-item active ml-2">
						<ActionButton 
							className={"nav-link text-success " + getActiveClassLink("/","active-success")}
							onClick={()=>props.history.push('/')} 
							text={props.t("HEADER_PUBLIC")} 
						/>
					</li>
					<li className="nav-item text-nowrap ml-2">
						<a className="nav-link" href="#" onClick={()=>props.history.push('/signin')}><FontAwesomeIcon icon={faSignInAlt} /> {props.t('HEADER_LOGIN')} </a>
					</li>
				</ul>
			</>
		)
	}

	let cl = togler ? "collapse navbar-collapse show" : "collapse navbar-collapse"

	return (
		<nav className="navbar fixed-top navbar-expand-lg navbar-light bg-white rounded navbar-cycle" aria-label="">
			<div className="container-fluid">
				<a className="navbar-brand" href="#" onClick={()=>{
					if(isLogin()){
						props.history.push('/cycles/prepared')
					}else{
						props.history.push('/')
					}
				}}> <img src={logo} /> <span> Learn Cycle </span></a>
				<button className="navbar-toggler" type="button" onClick={onClickTogler}>
					<span className="navbar-toggler-icon"></span>
				</button>
				<div className={cl}>
					{getAddButton()}
					{getLogOutButton()}
					{getLoginButton()}
				</div>
			</div>
		</nav>
	)
}

Header.propTypes={
	auth:PropTypes.object,
	history:PropTypes.object,
	t:PropTypes.object
}

export default withTranslation()(Header);