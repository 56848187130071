// import React from 'react';
// import ReactLoading from "react-loading";

// const Loading = (props) => (
//     <ReactLoading delay={0} type={props.type?props.type:'bubbles'} color={props.color?props.color:'#fd1d1d'} />
// );
  
// export default Loading;
  

import React from 'react';
// import { SemipolarLoading } from 'react-loadingg';
// const Container = () => <SemipolarLoading color="#fd1d1d" />;
// export default Container;

const Container = () => <Loading/>;
export default Container;


const Loading = () =>{
    return(
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            xmlnsXlink="http://www.w3.org/1999/xlink" 
            style={{margin: 'auto',background: 'transparent',display: 'block'}}
            width="120px" height="120px" 
            viewBox="0 0 100 100" 
            preserveAspectRatio="xMidYMid">
        <g transform="translate(50,50)">
            <circle 
                    cx="0" 
                    cy="0" 
                    r="6" 
                    fill="none" 
                    stroke="#aae7ca" 
                    strokeWidth="2" 
                    strokeDasharray="26.179938779914945 26.179938779914945">
                <animateTransform 
                    attributeName="transform" 
                    type="rotate" 
                    values="0 0 0;360 0 0" 
                    times="0;1" dur="1s" 
                    calcMode="spline" 
                    keySplines="0.2 0 0.8 1" begin="0" repeatCount="indefinite"></animateTransform>
            </circle>

            <circle cx="0" cy="0" r="12" fill="none" stroke="#1a94d6" strokeWidth="2" strokeDasharray="52.35987755982989 52.35987755982989">
                <animateTransform attributeName="transform" type="rotate" values="0 0 0;360 0 0" times="0;1" dur="1s" calcMode="spline" keySplines="0.2 0 0.8 1" begin="-0.2" repeatCount="indefinite"></animateTransform>
            </circle>

            <circle cx="0" cy="0" r="20" fill="none" stroke="#29b473" strokeWidth="2" strokeDasharray="78.53981633974483 78.53981633974483">
                <animateTransform attributeName="transform" type="rotate" values="0 0 0;360 0 0" times="0;1" dur="1s" calcMode="spline" keySplines="0.2 0 0.8 1" begin="-0.4" repeatCount="indefinite"></animateTransform>
            </circle>

            <circle cx="0" cy="0" r="28" fill="none" stroke="#149bcc" strokeWidth="2" strokeDasharray="104.71975511965978 104.71975511965978">
                <animateTransform attributeName="transform" type="rotate" values="0 0 0;360 0 0" times="0;1" dur="1s" calcMode="spline" keySplines="0.2 0 0.8 1" begin="-0.6" repeatCount="indefinite"></animateTransform>
            </circle>

            <circle cx="0" cy="0" r="36" fill="none" stroke="#f0672e" strokeWidth="2" strokeDasharray="130.89969389957471 130.89969389957471">
                <animateTransform attributeName="transform" type="rotate" values="0 0 0;360 0 0" times="0;1" dur="1s" calcMode="spline" keySplines="0.2 0 0.8 1" begin="-0.8" repeatCount="indefinite"></animateTransform>
            </circle>
        </g>
        </svg>
    )
}