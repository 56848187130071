import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpload } from '@fortawesome/free-solid-svg-icons'

const Avatar = (props) => {

    let style = {}

    if(props.width){
        style.width=props.width
    }

    if(props.height){
        style.height=props.height
    }

    const buildAvatar = (html) => {

        let clu = ""
        let iconUpload = ""
        let onClick = null

        if( props.upload){
            clu = "avatar--upload"
            iconUpload = <span className="avatar-icon"><FontAwesomeIcon icon={faUpload} /></span>
            onClick = props.onClick
        }

        return  <div onClick={onClick} className={"avatar " + clu} style={style}> {html} {iconUpload}</div>
    }

    const getCredentials = () =>{
        let t=""

        let tx = props.text.split(" ")

        if(tx[0] && tx[0][0]){
            t = tx[0][0]
        }

        if(tx[1] && tx[1][0]) t += tx[1][0]
        
        if(!t) return null
        
        return <span> {t.toLocaleUpperCase()}  </span> ;
    }

    if(props.path){
        style.backgroundImage = 'url('+props.path+')';
        return buildAvatar(null)
    }

    let textC = getCredentials()

    if(textC) return buildAvatar(textC)
    
    if(!textC) return props.text
    
    return null;
};

Avatar.defaultProps = {
    path:'/assets/img/avatar.png'
}

Avatar.propTypes = {
    width:PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    height:PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    text: PropTypes.string,
    path: PropTypes.string,
    upload:PropTypes.bool,
    onClick:PropTypes.func
}

export default Avatar;