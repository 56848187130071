import React,{Component} from 'react';

import PropTypes from 'prop-types';

import Quill from 'quill';
class EditorQuill extends Component{
    constructor(props){
        super(props)
        this.state = {}
        this.Editor = null
    }

    componentDidMount(){

        let that = this

        if(!this.Editor){
            this.Editor = new Quill('#editor-container', {
                modules: {
                  toolbar: [
                    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                    ['bold', 'italic', 'underline', 'strike', 'blockquote','code-block'],
                    [{ 'color': [] }, { 'background': [] }],
                    [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'},{ 'align': [] }],
                    ['link', 'image', 'video'],
                    [{ 'script': 'sub'}, { 'script': 'super' }],
                  ]
                },
                formats:[
                    'header', 'size',
                    'bold', 'italic', 'underline', 'strike', 'blockquote',
                    'list', 'bullet', 'indent',
                    'link', 'image', 'video','color','code-block','background','align','script','clean'
                ],
                placeholder: this.props.placeholder || 'Make a description for this cycle...',
                theme: 'snow'  // or 'bubble'
              });

              
        
              that.Editor.on('text-change', function(delta, oldDelta, source) {
                if (source == 'user') {
                    that.props.onChange(that.Editor.root.innerHTML)
                }else{
                    //source == 'api'
                }
            });

            if(this.Editor && this.props.value){
                this.Editor.root.innerHTML = this.props.value
            }
        }
    }

    render(){
        return <div id="editor-container"></div>
    }
}

EditorQuill.propTypes={
    value:PropTypes.string,
    placeholder:PropTypes.string
}

export default EditorQuill;