export const getTagFrom = (tags) => {
    let t = []
    if(tags && Array.isArray(tags)){
        tags.map((f)=>{
            if(f.name){
                t.push(f.name)
            }else{
                t.push(f)
            }
        })
    }else{
        if(tags && Object.keys(tags).length > 0 ){
            Object.keys(tags).map((tag)=>{
                if(tags[tag].name){
                    t.push(tags[tag].name)
                }else{
                    t.push(tags[tag])
                }
            })
        }
    }


    return t
}

export const getTagFromInOption = (tags) =>{
    let t = tags
   
    let newT = []
    
    t.map((tag)=>{
        newT.push({
            value:tag,
            label:tag
        })
    })
    return newT
}