import React from 'react';
import Layout from "../components/Layout"

const AboutUS = (props) => {
    
    const lng = window.localStorage.getItem('i18nextLng') || "en"

    let contentEn =(
        <div>
            <br />
            <h2>He, We are Learn Cycle?</h2>
            <p>Learn cycle helps you achieve excellence learning new things which you are curious, and do not want to forget.</p>
            <p>
                Everyone has found themselves where the things they learn are forgotten after a period of time and has that sensation that I have in mind but I do not know how to express it because I do not understand. 
                Learn Cycle was created from this to help every person to learn quickly and the most important thing, remember it. 
                We think that more such tools should be created to help us in the learning process and we hope to be able to help in that.
            </p>
        </div>
    );

    let contentSq =(
        <div>
            <br />
            <h2>Hi, Ne jemi Learn Cycle?</h2>
            <p>Learn cycle ju ndihmon të arrini përsosmëri duke mësuar gjëra të reja, juve që jeni kurioz dhe nuk doni t'i harroni.</p>
            <p>
                Gjithkush e ka gjetur veten atje ku gjërat që mësojnë harrohen pas një periudhe kohe dhe kanë atë ndjesi që kam në mendje por nuk di si ta shpreh 
                sepse nuk e kuptoj.
                Learn Cycle u krijua nga kjo për të ndihmuar çdo person të mësojë shpejt dhe gjëja më e rëndësishme të mos e harroni atë.
                Ne mendojmë se duhet të krijohen më shumë mjete të tilla për të na ndihmuar në procesin e të mësuarit 
                dhe shpresojmë të jemi në gjendje të ju ndihmojmë në këtë.
            </p>
        </div>
    );

    return(
        <>
            <Layout withSidebar={false} {...props} >
                { lng == "sq" ? contentSq : contentEn }

            </Layout>
        </>
    );
}

export default AboutUS;