import React, { Component } from 'react';
import Api from '../helpers/api';
import Layout from '../components/Layout';
import Calendar from '../components/Calendar';
import PropTypes from 'prop-types';

import Loading from '../components/Loading';
import moment from 'moment';

import Modal,{ModalBody} from '../components/Modal'

import SaveForLater from '../elements/SaveForLater'

import { withTranslation } from 'react-i18next';

class CalendarList extends Component {

    constructor(props) {
        super(props);

        this.state = {
            load:false,
            results:[]
        }

        this.getData = this.getData.bind(this)
        this.onWeek = this.onWeek.bind(this)
        this.onMonth = this.onMonth.bind(this)
        this.onNavigate = this.onNavigate.bind(this)
        this.onSelectSlog = this.onSelectSlog.bind(this)
        this.onCloseModalSave = this.onCloseModalSave.bind(this)
    }

    componentDidMount(){
        this.getData()
    }

    getData(start=null,end=null){

        if(!start && !end ){
            this.setState({load:true})
        }

        try {
            let url = 'cycles/calendar'

            let today = moment()

            start   =  start || today.clone().startOf('month').format('YYYY-MM-DD HH:mm')
            end     =  end || today.clone().endOf('month').format('YYYY-MM-DD HH:mm')

            Api.get(url,{
                start:start,
                end:end
            }).then((response) => {
                this.setState({
                    results:response.result,
                    load:false
                })
            })
            .catch(() => {
                this.setState({load:false})
            });
        } catch (error) {
            console.log("error",error)
            this.setState({load:false})
        }
    }

    onSelectSlog(start,end) {
        console.log(start,end)
        this.setState({tmp_date:start})
        document.getElementById("savelater-modal").click();
    }

    onCloseModalSave(){
        document.getElementById('savelater-modal-dismis').click()
        this.setState({tmp_date:null})
        this.getData()
    }

    onNavigate(date,view,action){
        switch (view) {
            case 'week':
                this.onWeek(date,action);
            break;

            default:
                this.onMonth(date,action)
                break;
        }
    }

    onWeek(date,action){
        console.log(date,action,moment(date).format('YYYY-MM-DD HH:mm'))

        let start = moment(date)
        start.set("hour",0)
        start.set("minute",0)
        start.set("second",0)

        let end = moment(date)
        end.add(6,"days")
        end.set("hour",23)
        end.set("minute",59)
        end.set("second",59)
        
        this.getData(
            start.format('YYYY-MM-DD HH:mm'),
            end.format('YYYY-MM-DD HH:mm')
        )
    }

    onMonth(date,action){
        console.log(date,action,moment(date).format('YYYY-MM-DD HH:mm'))
        
        let start = moment(date)
        start.set("hour",0)
        start.set("minute",0)
        start.set("second",0)

        let end = moment(date).endOf("month")
        end.set("hour",23)
        end.set("minute",59)
        end.set("second",59)
        
        this.getData(
            start.format('YYYY-MM-DD HH:mm'),
            end.format('YYYY-MM-DD HH:mm')
        )
    }

    renderCalendar(){
        const {results} = this.state

        if( !results || results.length == 0 && this.state.load ){
            return (
                <div className="row">
                    <div className="col-md-12">
                        <div style={{margin:'auto',width:'200px',position:"relative",minHeight:'100px'}}>
                            <Loading />
                        </div>
                    </div>  
                </div>
            )
        }

        let date = moment(this.state.tmp_date).format('YYYY-MM-DD')

        return <x>
            <Calendar 
                data={this.state.results||[]} 
                history={this.props.history} 
                onNavigate={this.onNavigate} 
                load={this.state.load}
                onSelectSlot={this.onSelectSlog}
            />
                 <Modal
                    id="savelater"
                    title={this.props.t('CALENDAR_PRENOTATE_DATE') + (date)}
                    nobutton={true}
                >
                    <ModalBody>
                        {this.state.tmp_date ? <SaveForLater onSuccess={this.onCloseModalSave} date={date} /> : null}
                    </ModalBody>
                </Modal>    
            </x>
    }

    render() {
        return (
            <Layout history={this.props.history} withSidebar={false}>
                <br/>
                {this.renderCalendar()}
		    </Layout>
        );
    }
}


CalendarList.propTypes = {
    history:PropTypes.object,
    t:PropTypes.object
}

export default withTranslation()(CalendarList)
