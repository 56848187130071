import React,{  } from "react";
import PropTypes from 'prop-types';

import { useTable,useRowSelect } from "react-table";

import { getTagFrom } from "../helpers/index";

import {TagView} from '../components/Tag'

import LabelRepetition from '../components/LabelRepetition'
import LabelRepeat, { InCycle } from '../components/LabelRepeat'

import { NavLink } from 'react-router-dom';
import Favorite from "./Favorite";

// const IndeterminateCheckbox = React.forwardRef(
//     ({ indeterminate, ...rest }, ref) => {
//       const defaultRef = React.useRef()
//       const resolvedRef = ref || defaultRef
  
//       React.useEffect(() => {
//         resolvedRef.current.indeterminate = indeterminate
//       }, [resolvedRef, indeterminate])
  
//       return (
//         <>
//           <input type="checkbox" ref={resolvedRef} {...rest} />
//         </>
//       )
//     }
//   )

const Table = (props) => {

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        // state: { 
		// 	//selectedRowIds
		// },
      } = useTable({
                columns:props.columns,
                data:props.data,
            },
            useRowSelect,
            hooks => {
			hooks.visibleColumns.push(columns => [
					// Let's make a column for selection
					// {
					// 	id: 'selection',
					// 	// The header can use the table's getToggleAllRowsSelectedProps method
					// 	// to render a checkbox
					// 	Header: ({ getToggleAllRowsSelectedProps }) => (
					// 		<div>
					// 			<IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
					// 		</div>
					// 	),
					// 	// The cell can use the individual row's getToggleRowSelectedProps method
					// 	// to the render a checkbox
					// 	Cell: ({ row }) => (
					// 		<div>
					// 			<IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
					// 		</div>
					// 	),
					// },
					...columns,
					// {
					// 	id: 'actions',
					// 	// The header can use the table's getToggleAllRowsSelectedProps method
					// 	// to render a checkbox
					// 	Header: ({ getToggleAllRowsSelectedProps }) => (
					// 		<div>
					// 			action
					// 		</div>
					// 	),
					// 	// The cell can use the individual row's getToggleRowSelectedProps method
					// 	// to the render a checkbox
					// 	Cell: ({ row }) => (
					// 		<div>
					// 			view
					// 		</div>
					// 	),
					// },
					]
				)
			}
		);

	const getValueCell = (cell,i) =>{
		const { key } = cell.getCellProps()

		if( key == "cell_"+i+"_tags" ){
			return <TagView options={getTagFrom(cell.row.original.cycle.tags)} />
		}

		if( key == "cell_"+i+"_repetition" ){
			return <div>
						<InCycle isRepeated={cell.row.original.isRepeated} status={cell.row.original.status} /> {" "}
						<LabelRepeat repetition={cell.row.original.repetition} status={cell.row.original.status} /> {" "}
						<LabelRepetition value={cell.value} />
					</div>
		}

		if( key == "cell_"+i+"_name" ){
			return <NavLink to={"/cycle/view/"+cell.row.original.cycle.short_link}> {cell.row.original.cycle.name}</NavLink>
		}

		if( key == "cell_"+i+"_isFavorite" ){
			return <Favorite value={cell.value} />
		}

		return cell.render("Cell")
	}
	
	return (
		<table className="table bg-light bg-rounded rounded table-hover"  {...getTableProps()} style={{opacity:props.load?0.2:1}}>
			<thead>
			{headerGroups.map((headerGroup,i) => (
				<tr key={i+"-header-row"} {...headerGroup.getHeaderGroupProps()}>
					{headerGroup.headers.map((column,i) => (
						<th key={i+"-header-row"} {...column.getHeaderProps()}>{
							column.render("Header")}
						</th>
					))}
				</tr>
			))}
			</thead>
			<tbody {...getTableBodyProps()}>
			{rows.map((row, i) => {
				prepareRow(row);
				return (
					<tr key={i+"-tr-row"}  {...row.getRowProps()}>
						{row.cells.map((cell,i) => {
							return <td key={i+"tr-td-row"} {...cell.getCellProps()} className={"elipsis"}>{getValueCell(cell,i)}</td>;
						})}
					</tr>
				);
			})}
			{
				rows.length == 0 ? <tr><td className="text-center" colSpan={props.columns.length}>No cycles in this view!</td></tr> : null
			}
			</tbody>
		</table>
	);
}

Table.propTypes = {
    columns: PropTypes.array,
	data:PropTypes.array,
	load:PropTypes.bool
}


export default Table;