import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCrown } from '@fortawesome/free-solid-svg-icons'

const Favorite = (props) => {

    let color = "gray"


    if(props.value == "true" || props.value == true){
        color="red"
    }

    return  <FontAwesomeIcon icon={faCrown} onClick={props.onClick || null} color={color} style={props.onClick ? {cursor:'pointer'} : {}} /> 
}

Favorite.propTypes={
    value:PropTypes.bool,
    onClick:PropTypes.func
}

export default Favorite;