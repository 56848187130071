import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Brand from '../components/Brand';

import Footer from '../components/Footer';
import Button from '../components/Button'

import Api from '../helpers/api'

import Notification from "../components/Notification";
import Header from '../components/Header';

import { withTranslation } from 'react-i18next';

const ResetPassword = (props) => {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [password_confirmation, setPasswordConfirmation] = useState("");
    const [load, setLoad] = useState(false);

    const [hasErrorEmail,setHaserroremail] = useState("")
    const [hasErrorPassword,setHaserrorepassword] = useState("")
    const [hasErrorPasswordConfirmation,setHaserrorepasswordconfirmation] = useState("")

    if(!props.match.params.token){
        props.history.push('/login')
    }

    const onSave = () => {

        setLoad(true)

        Api.post('password/reset',{email,password,password_confirmation,token:atob(props.match.params.token)}).then((response)=>{
            setLoad(false)
            if(response.status=="success"){
                Notification.notify({
                    message:props.t("RESET_PASS_NOTIS_SUCCESS"),
                    type:"success"
                });
                props.history.push('/signin');
            }else{
                if(response.email){
                    setHaserroremail(response.email)
                    Notification.notify({message:response.email,type:"danger"});
                }else{
                  setHaserroremail("")
                }
                if(response.password){
                    setHaserrorepassword(response.password)
                    Notification.notify({message:response.password,type:"danger"});
                }else{
                  setHaserrorepassword("")
                }
                if(response.password_confirmation){
                    setHaserrorepasswordconfirmation(response.password_confirmation)
                    Notification.notify({message:response.password_confirmation,type:"danger"});
                }else{
                  setHaserrorepasswordconfirmation("")
                }

                if(response.result && response.result == "passwords.token"){

                    Notification.notify({
                        message:props.t("RESET_PASS_NOTIS_TOKEN"),
                        type:"danger"
                    });
                }

            }
        }).catch(()=>{
            setLoad(false)

            Notification.notify({
                message:props.t("RESET_PASS_TRY_AGAIN"),
                type:"danger"
            });
        })
    }
    
    return (
        <>
            <Header {...props}/>
            <main className="signup">
                <form >
                    <Brand />
                    {/* <h1 className="h1 mb-3 fw-normal text-center">Set your new password</h1> */}
                    <input onChange={(e)=>{setEmail(e.target.value);setHaserroremail("")}} type="email" id="inputEmail" className={"form-control " + (hasErrorEmail ? "is-invalid" : "")} placeholder={props.t("SIGNUP_EMAIL")} required autoFocus value={email}/>
                    <input onChange={(e)=>{setPassword(e.target.value);setHaserrorepassword("")}}  type="password" id="inputPassword" className={"form-control " + (hasErrorPassword ? "is-invalid" : "")} placeholder={props.t("SIGNUP_PASSWORD")}required value={password}/>
                    <input onChange={(e)=>{setPasswordConfirmation(e.target.value);setHaserrorepasswordconfirmation("")}}  type="password" id="inputPasswordC" className={"form-control " + (hasErrorPasswordConfirmation ? "is-invalid" : "")} placeholder={props.t("RESET_PASS_CONFIRM_PASS")} required value={password_confirmation}/>
                
                    {<Button className="w-100 btn btn-lg btn-primary btn-signin" load={load} onClick={onSave}>{props.t("RESET_PASS_SAVE")}</Button>}
                </form>
            </main>
            <Footer />
        </>
    );
};

ResetPassword.propTypes = {
    match: PropTypes.object,
    history:PropTypes.object,
    t:PropTypes.object
}

export default withTranslation()(ResetPassword);