
import React from 'react'

import PropTypes from 'prop-types';

import _ from "lodash";

import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';

import makeAnimated from 'react-select/animated';

import Api from '../helpers/api';

import { getTagFromInOption } from "../helpers/index";

import {isLogin} from "../utils/index";

import {setQueryStringParams,getQueryStringParams} from '../helpers/filters'

const animatedComponents = makeAnimated();

class Tag extends React.Component{
    constructor(props){
        super(props)
        this.state ={
            loading:false,
            options:[],
            value:{}
        }

        this.initialRequest = this.initialRequest.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.loadOptions =  _.debounce(this.loadOptions.bind(this),1000)
        this.handleInputChange = this.handleInputChange.bind(this)
        
    }
    componentDidMount(){
        const {value} = this.props
        if(value){
            this.setState({
                value:getTagFromInOption(value)
            })
        }

        this.initialRequest(value)
    }
    handleChange(newValue){
        const v = newValue.map(f=>f.value && f.value.trim())
      
        this.setState({value:newValue})

        if(this.props.onChange){
            this.props.onChange(v)
        }
    }

    handleInputChange(value,action){
        if(action.action == "input-change"){
            this.loadOptions(value);
        }
    }
    initialRequest(value){

            let url = "tags/default"

            if(!isLogin()){
                url = "tags/default/shared"
            }

            try {
                Api.get(url,{search:value}).then((response)=>{
                    if(response.result){
                        this.setState({
                            options:response.result
                        })
                    }
                }).catch(()=>{
                    this.setState({
                        options:[]
                    })
                })
            } catch (error) {
                this.setState({
                    options:[]
                })
            }
    }
    loadOptions(value){
        this.setState({loading:true})

        let url = "tags/search"

        if(!isLogin()){
            url = "tags/search/shared"
        }

        try {
            Api.get(url,{search:value}).then((response)=>{
                if(response.result){
                    this.setState({options:response.result})
                }
                this.setState({loading:false})
            }).catch(()=>{
                this.setState({loading:false})
            })
        } catch (error) {
            this.setState({loading:false})
        }
    }
    render(){

        if(!this.props.shouldCreate){
            return <Select
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                        value={this.state.value}
                        isMulti
                        isClearable
                        options={this.state.options}
                        onChange={this.handleChange}
                        placeholder={"Tag"}
                        isLoading={this.state.loading}
                        onInputChange={this.handleInputChange}
                    />
        }
            
        return(
            <CreatableSelect
                closeMenuOnSelect={false}
                components={animatedComponents}
                value={this.state.value}
                isMulti
                isClearable
                options={this.state.options}
                onChange={this.handleChange}
                placeholder={"Tag"}
                isLoading={this.state.loading}
                onInputChange={this.handleInputChange}
            />
        )
    }
}


Tag.propTypes = {
    value: PropTypes.array,
	onChange:PropTypes.func,
	shouldCreate:PropTypes.bool
}

export default Tag

const TagView = (props) =>{

    const onClickTag = (tag) =>{

        const filters = Object.assign({},getQueryStringParams('filters'),{tags:[tag]})
        
        setQueryStringParams(filters,'filters')

        if(props.onClick){
            props.onClick()
        }
    }

    return(
        props.options.map((tag,key)=>{
            return <span 
                        onClick={!props.single ? ()=>onClickTag(tag) : null} 
                        key={'tag-'+key} 
                        className="badge rounded-pill bg-dark" 
                        style={{
                            marginRight:"3px",
                            cursor:'pointer'
                        }}>{tag}</span>
        })
    );
}

export {TagView}
