import React from 'react';
import PropTypes from 'prop-types';


import logo from '../assets/images/clogo.png'

import '../assets/sass/components/brand.scss'

const Brand = (props) => {

    const style = {}

    if(props.width){
        style.width=props.width
    }

    if(props.height){
        style.height=props.height
    }

    return (
        <img className="mb-3 brand" src={logo} alt="" style={style} />
    );
};

Brand.propTypes = {
    width:PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    height:PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
}

export default Brand;